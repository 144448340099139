import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

export const MODAL_NONE = 'none';
export const MODAL_CURRENT_STORE = 'current';
export const MODAL_SELECT_STORE = 'select';
export const MODAL_SELECT_STORE_DELIVERY = 'select delivery';
const modalSet = new Set([
  MODAL_NONE,
  MODAL_CURRENT_STORE,
  MODAL_SELECT_STORE,
  MODAL_SELECT_STORE_DELIVERY,
]);

const initialState = {
  currentModal: MODAL_NONE,
};

const storeSelectionModalSlice = createSlice({
  name: 'storeSelectionModal',
  initialState,
  reducers: {
    setCurrentModal(state, action) {
      const currentModal = modalSet.has(action.payload)
        ? action.payload
        : MODAL_NONE;
      return { ...state, currentModal };
    },
  },
  extraReducers,
});

export const { setCurrentModal } = storeSelectionModalSlice.actions;
export default storeSelectionModalSlice;
