import { parseCookies } from 'nookies';
import logger from 'config/logger';
import { CURRENT_USER_SCHEMA_VERSION } from 'constants/user';
import { RequestInstance } from 'request';
import userSlice from 'stores/reducers/user';
import isBrowser from 'utils/is-browser';

const USER_INFO_KEY = 'userInfo';

export const COOKIE_GIP_UID = '__uid';

const DEFAULT_USER_INFO = {
  dateOfBirth: '',
  deliveryAddress: '',
  email: '',
  emailVerified: '',
  firstName: '',
  identityPlatformId: '',
  isEnrolledMarketing: false,
  isEnrolledOrderCommunication: false,
  klaviyoId: '',
  klaviyoProperties: {},
  lastName: '',
  location: '',
  phoneNumber: '',
  preferredStore: '',
  springBigId: '',
  userSchemaVersion: '',
  zipCode: '',
};

export const deleteUserInfo = (dispatch) => {
  if (isBrowser) {
    window.localStorage.removeItem(USER_INFO_KEY);
    dispatch(
      userSlice.actions.updateUserInformation({
        isInitialState: false,
        isLoggedIn: false,
        userInfo: { ...DEFAULT_USER_INFO },
      })
    );
  }
};

export const getUserInfoFromLocalStorage = () => {
  if (isBrowser) {
    const userInfo = window?.localStorage?.getItem?.(USER_INFO_KEY);
    const parsedUserInfo = (userInfo && JSON.parse(userInfo)) ?? {
      ...DEFAULT_USER_INFO,
    };
    return parsedUserInfo;
  }
  return { ...DEFAULT_USER_INFO };
};

export const setUserInfoFromLocalStorage =
  (forceUpdateEnabled) => async (dispatch) => {
    // DELETE top half of this once migration is complete
    try {
      let forceUpdate = false;
      if (forceUpdateEnabled) {
        const { versionUpNeeded } = await getAccountVersionDetails();
        forceUpdate = versionUpNeeded;
      }

      if (forceUpdate) {
        dispatch(deleteUserInfo);
      } else {
        const parsedUserInfo = getUserInfoFromLocalStorage();
        if (parsedUserInfo.userSchemaVersion !== CURRENT_USER_SCHEMA_VERSION) {
          fetchUserData().then((userData) => {
            dispatch(setUserInfo(userData));
          });
          return;
        }

        dispatch(
          userSlice.actions.updateUserInformation({
            isInitialState: false,
            isLoggedIn:
              typeof parsedUserInfo.email === 'string' &&
              parsedUserInfo.email.length > 0,
            userInfo: parsedUserInfo,
          })
        );
      }
    } catch (err) {
      logger.error(err, 'Error hydrating user information');
    }
  };

export const setUserLocation = (location) => (dispatch) => {
  if (isBrowser) {
    const currentUserInfo = getUserInfoFromLocalStorage();
    const updatedUserInfo = { ...currentUserInfo, location };
    window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(updatedUserInfo));
    dispatch(
      userSlice.actions.updateUserInformation({
        isInitialState: false,
        isLoggedIn:
          typeof currentUserInfo.email === 'string' &&
          currentUserInfo.email.length > 0,
        userInfo: updatedUserInfo,
      })
    );
  }
};

export const setUserInfo = (userInfo) => (dispatch) => {
  if (isBrowser) {
    const currentUserInfo = getUserInfoFromLocalStorage();
    const updatedUserInfo = { ...currentUserInfo, ...userInfo };
    window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(updatedUserInfo));
    dispatch(
      userSlice.actions.updateUserInformation({
        isInitialState: false,
        isLoggedIn:
          typeof updatedUserInfo.email === 'string' &&
          updatedUserInfo.email.length > 0,
        userInfo: updatedUserInfo,
      })
    );
  }
};

export const setUserDeliveryAddress = (deliveryAddress) => (dispatch) => {
  if (isBrowser) {
    const currentUserInfo = getUserInfoFromLocalStorage();
    const updatedUserInfo = { ...currentUserInfo, deliveryAddress };
    window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(updatedUserInfo));
    dispatch(
      userSlice.actions.updateUserInformation({
        isInitialState: false,
        isLoggedIn:
          typeof currentUserInfo.email === 'string' &&
          currentUserInfo.email.length > 0,
        userInfo: updatedUserInfo,
      })
    );
  }
};

/**
 * @async
 * function getAccountVersion
 * @returns {Promise<{versionUpNeeded:boolean}>} account version info.
 */
export const getAccountVersionDetails = async () => {
  try {
    const response =
      (await RequestInstance.get(`api/account/version-details`)) ?? {};
    return response || {};
  } catch (err) {
    logger.error(err, 'Error checking account version');
  }
  return {};
};

const fetchUserData = async () => {
  const cookies = parseCookies();
  const cookieGipUid = cookies[COOKIE_GIP_UID];
  try {
    if (!cookieGipUid) {
      return DEFAULT_USER_INFO;
    }
    const { userInfo } = await RequestInstance.get('api/account/user');
    return userInfo;
  } catch (err) {
    if (err?.message === 'Authorization is required. Please log in.') {
      logger.warn(err?.message);
    } else {
      logger.error(err, 'Error getting user data');
    }

    return DEFAULT_USER_INFO;
  }
};
