import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'components/Image';
import Link from 'components/Link';
import { setAgeRestrictionAcceptance } from 'stores/actions/age-gate';
import styles from './AgeGateModal.module.scss';

const buttonClasses =
  'border-2 border-primary w-100 w-sm-auto px-24 py-6 fs-10';

function AgeGateModal({
  header = 'Hello.',
  advisory = 'Are you older than 21 or have a medical card?',
  age_restriction_failed_message:
    ageRestrictionFailedMessage = 'We’re sorry, we take seriously our responsibility to limit website access to adults 21 years and older or have a medical card.',
  image = {},
}) {
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const ageRestrictionAccepted = useSelector((store) => store.ageGate);
  const dispatch = useDispatch();

  useEffect(() => {
    setShowModal(!ageRestrictionAccepted);
  }, [ageRestrictionAccepted]);

  const handleYesClick = () => {
    dispatch(setAgeRestrictionAcceptance(true));
  };

  const handleNoClick = () => setShowMessage(true);

  return (
    <Modal
      centered
      backdropClassName="opacity-85"
      id="age-gate-modal"
      show={showModal}
      size="lg"
    >
      <Modal.Body className="p-0 rounded border-primary border-2">
        {image?.url && (
          <div
            className={classNames(
              'position-relative w-100 d-flex justify-content-center align-items-start mt-20 mb-8',
              styles.image_container
            )}
          >
            <Image
              fill
              src={image?.url}
              alt={image?.description ?? 'Lume Logo'}
            />
          </div>
        )}
        <div
          className={classNames(
            'd-flex flex-column align-items-center mb-20',
            { 'mt-4': image?.url },
            { 'mt-20': !image?.url }
          )}
        >
          <div className={classNames('text-primary p-0 ', styles.header)}>
            {header}
          </div>
          <div
            className={classNames(
              'text-primary px-8 fs-14 fs-xl-24 text-center',
              styles.advisory
            )}
          >
            {advisory}
          </div>
          <div className="d-flex flex-column w-75 w-lg-50 justify-content-center text-center py-12">
            <Button
              className={classNames(
                'mb-2 mb-lg-4',
                buttonClasses,
                styles.button
              )}
              variant="secondary"
              onClick={handleYesClick}
            >
              Yes
            </Button>
            <Button
              className={classNames(
                'mt-2 mt-lg-4',
                buttonClasses,
                styles.button
              )}
              variant="secondary"
              onClick={handleNoClick}
            >
              No
            </Button>
          </div>
          <div
            className={classNames(
              'd-flex flex-column w-100 fs-7 justify-content-center text-center text-primary px-8',
              styles.links
            )}
          >
            <span>
              By accessing this site, you accept the{' '}
              <Link
                aria-label="Privacy Policy"
                className="fw-bold"
                href="/privacy"
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                aria-label="Terms and Conditions"
                className="fw-bold"
                href="/terms"
              >
                Terms and Conditions
              </Link>
              .
            </span>
          </div>
          {showMessage ? (
            <div
              className={classNames(styles.error, 'fs-7 text-center px-8 pt-8')}
            >
              {ageRestrictionFailedMessage}
            </div>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AgeGateModal;
