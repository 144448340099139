/**
 * Note that this instance of the logger should not be imported directly.
 * Please import from 'config/logger' and webpack will properly use this instance.
 *
 * If this behavior needs to be modified, you can do so in the .next.config.js file
 */
import pino from 'pino';
import options from './options';

const logger = pino({ ...options, name: 'BROWSER' });

export default logger;
