import { createSlice, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const SLICE_NAME = 'productPromotions';
export const initialState = {
  promotions: {},
};

const productPromoSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setProductPromotions(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(createAction(HYDRATE), (state, action) => {
      return {
        ...state,
        ...action.payload[SLICE_NAME],
      };
    });
  },
});

export const { setProductPromotions } = productPromoSlice.actions;
export default productPromoSlice;
