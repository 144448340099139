// dutchie-responses
export const DUTCHIE_VALIDATION_KEYWORD = 'validation errors';
export const DUTCHIE_PURCHASE_LIMIT_KEYWORD = 'purchase limit';
export const DUTCHIE_NOT_FOUND_KEYWORD = 'not found';
export const DUTCHIE_INVALID_VALUE_KEYWORD = 'got an invalid value';
export const ALL_DUTCHIE_KNOWN_ERROR_KEYWORDS = [
  DUTCHIE_PURCHASE_LIMIT_KEYWORD,
  DUTCHIE_VALIDATION_KEYWORD,
  DUTCHIE_NOT_FOUND_KEYWORD,
  DUTCHIE_INVALID_VALUE_KEYWORD,
];
