import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const initialState = {
  id: '',
  lineItems: [],
  recentlyAddedSKU: null,
  scope: '',
  redirectUrl: null,
  totalPrice: {
    centAmount: 0,
    currencyCode: 'USD',
    fractionDigits: 2,
  },
  limitError: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    changeScope(_, action) {
      return { ...initialState, ...action.payload };
    },
    resetRecentlyAdded(state) {
      return { ...state, recentlyAdded: null };
    },
    setRecentlyAdded(state, action) {
      return { ...state, recentlyAdded: action.payload };
    },
    setCartLimitError(state, action) {
      return { ...state, limitError: action.payload };
    },
    updateCart(state, action) {
      return { ...state, ...action.payload };
    },
    resetCart() {
      return { ...initialState };
    },
  },
  extraReducers,
});

export const { changeScope, updateCart, resetCart, setCartLimitError } =
  cartSlice.actions;
export default cartSlice;
