import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useRichLinkClicks from 'hooks/useRichLinkClicks';
import {
  MODAL_SELECT_STORE,
  MODAL_SELECT_STORE_DELIVERY,
  setCurrentModal,
} from 'stores/reducers/store-selection-modal';
import isEmpty from 'utils/is-empty';
import DynamicDomainFooter from '../DynamicDomainFooter';
import DynamicDomainHeader from '../DynamicDomainHeader';

function DynamicDomainLayout({ children, footer, header }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const interceptRichLinkClicks = useRichLinkClicks(router);
  const deliveryEnabled = useSelector((state) => state.featureFlags.delivery);
  const { modalOpen } = useSelector(({ modalOpen }) => ({
    modalOpen,
  }));

  const { storeSelector } = router.query;

  useEffect(() => {
    if (!isEmpty(storeSelector)) {
      const shoppingMode =
        storeSelector.toLowerCase() === 'pickup' || !deliveryEnabled
          ? MODAL_SELECT_STORE
          : MODAL_SELECT_STORE_DELIVERY;
      dispatch(setCurrentModal(shoppingMode));
    }
  }, [deliveryEnabled, dispatch, storeSelector]);
  return (
    <div aria-hidden={modalOpen ? true : null}>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {/*
       * There should be no way for header to not exist, and yet this is happening server-side
       * during the build process.
       *
       * Fixing this will come in a follow up.
       */}
      {header && <DynamicDomainHeader {...header} />}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <main className="page-container" onClick={interceptRichLinkClicks}>
        {children}
      </main>
      {footer && <DynamicDomainFooter {...footer} />}
    </div>
  );
}

export default DynamicDomainLayout;
