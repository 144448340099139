export const DYNAMIC_DOMAIN_MAP = JSON.parse(
  process.env?.DYNAMIC_DOMAIN_MAP ?? '{}'
);

export const DYNAMIC_DOMAIN_BLACK_LIST = new RegExp(
  process.env?.DYNAMIC_DOMAIN_BLACK_LIST || '/*/'
);

export const DYNAMIC_DOMAIN_ROOT = '/_dynamic-domain';

export const DYNAMIC_MENU_HEADER_BUTTON = 'Button';
export const DYNAMIC_MENU_HEADER_DISPLAY_OVERLAY = 'Overlay';
export const DYNAMIC_MENU_HEADER_DISPLAY_STACKED = 'Stacked';
export const DYNAMIC_MENU_HEADER_LINK = 'Link';
export const DYNAMIC_MENU_LAUNCH_STORE_SELECTOR = 'Launch Store Selector';
export const DYNAMIC_MENU_LEFT_POSITION = 'Left';
export const DYNAMIC_MENU_RIGHT_POSITION = 'Right';
