import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import {
  setCurrentModal,
  MODAL_NONE,
  MODAL_SELECT_STORE,
  MODAL_SELECT_STORE_DELIVERY,
} from 'stores/reducers/store-selection-modal';
import omit from 'utils/omit';
import styles from './StoreSelection.module.scss';
import MapIcon from '../../svg/MapIcon';

const ModalSelectStore = dynamic(() => import('components/ModalSelectStore'), {
  ssr: false,
});

export default function StoreSelection({
  google,
  hideTrigger = false,
  selectedStore,
  setStoreData,
  storeData,
  storeSelectionContent,
  onStoreSelection,
}) {
  const router = useRouter();
  const DELIVERY = 'delivery';
  const PICKUP = 'pickup';
  const STORE_SELECTOR_MODES = new Set([
    MODAL_SELECT_STORE,
    MODAL_SELECT_STORE_DELIVERY,
  ]);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { currentModal = MODAL_NONE } =
    useSelector((state) => state.storeSelectionModal) ?? {};
  const closeModal = useCallback(() => {
    dispatch(setCurrentModal(MODAL_NONE));
    if (router.query.storeSelector) {
      router.push(
        `?${new URLSearchParams(omit(router.query, 'storeSelector'))}`,
        undefined,
        { shallow: true }
      );
    }
  }, [dispatch, router]);

  return (
    <>
      {!hideTrigger && (
        <Col
          xl="auto"
          className="d-flex flex-column mx-3 mx-xl-0 font-geo position-relative"
        >
          <Button
            ref={ref}
            aria-label={
              selectedStore
                ? `You're shopping at the ${selectedStore} store - change your store here`
                : 'Select a store'
            }
            className={`${styles.store} text-decoration-none text-uppercase text-primary fw-xl-600 p-0`}
            variant="none"
            type="button"
            onClick={() => dispatch(setCurrentModal(MODAL_SELECT_STORE))}
          >
            <MapIcon color="#212529" />
            <span className="me-6" data-value="selected-store-from-nav">
              {selectedStore || 'Select a Store'}
            </span>
            <span className={classNames('d-inline-block', styles.chevron_icon)}>
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </Button>
        </Col>
      )}
      {STORE_SELECTOR_MODES.has(currentModal) && (
        <ModalSelectStore
          showModal
          closeModal={closeModal}
          google={google}
          mode={currentModal === MODAL_SELECT_STORE ? PICKUP : DELIVERY}
          setStoreData={setStoreData}
          storeData={storeData}
          storeSelectionContent={storeSelectionContent}
          onStoreSelection={onStoreSelection}
        />
      )}
    </>
  );
}
