/**
 * Format the store name for use in queries
 * to api backends
 *
 * @param {string} storeName
 * @returns {string}
 */

function formatStoreName(storeName = '') {
  return storeName?.toLowerCase()?.replace(/\s|\./g, '') || '';
}

export default formatStoreName;
