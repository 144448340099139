import { memo, useState, useEffect, useId } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import LegacyImage from 'next/legacy/image';
import qs from 'qs';
const MAX_RESP_DPR = 4;
import { requestIdleCallback } from 'utils/request-idle-callback';

/**
 * this loader fulfills the content stack image api contract
 */
const loader = ({ src, height, width, quality = 75 }) => {
  if (!src.includes('contentstack')) {
    const parameters = qs.stringify({
      auto: 'format',
      fit: 'fill',
      w: width,
      h: height,
      q: 75,
    });
    return `${src}?${parameters}`;
  }

  const parameters = qs.stringify({
    width,
    quality,
    auto: 'webp',
    format: 'webply',
  });
  return `${src}?${parameters}`;
};

/** optimizeSizes
 * converts assumed DPR1 sizes to account for pixel density
 *
 * example output when given '(max-width: 992px) 50vw, 25vw':
 *
 * (max-width: 992px) and (-webkit-min-device-pixel-ratio: 3) 17vw,
 * (-webkit-min-device-pixel-ratio: 3) 9vw,
 * (max-width: 992px) and (-webkit-min-device-pixel-ratio: 2) 25vw,
 * (-webkit-min-device-pixel-ratio: 2) 13vw,
 * (max-width: 992px) and (-webkit-min-device-pixel-ratio: 1) 50vw,
 * (-webkit-min-device-pixel-ratio: 1) 25vw, 100vw
 */
function generateLowResSizes(sizes) {
  let optimizedSizes = sizes;
  try {
    const splitSizes = sizes?.split(',');
    if (splitSizes?.length && !sizes.includes('device-pixel-ratio')) {
      const parsedSizes = [];
      for (const splitSize of splitSizes) {
        const sizeParts = splitSize.split(' ');
        const numPart = Number.parseInt(
          sizeParts[sizeParts.length - 1]?.match(/\d+/)[0],
          10
        );
        if (Number.isNaN(numPart)) {
          parsedSizes.push({ sizeParts, numPart: 100 });
        } else {
          parsedSizes.push({ sizeParts, numPart });
        }
      }
      const newSizes = [];
      for (let i = MAX_RESP_DPR; i > 0; i--) {
        const parsedSizesLen = parsedSizes.length;

        for (let j = 0; j < parsedSizesLen; j++) {
          const parsedSize = parsedSizes[j];
          const newDprNum =
            i === 1
              ? parsedSize.numPart
              : Math.ceil(
                  parsedSize.numPart / // *1.5
                    i
                );
          const newEndUnit =
            parsedSize.sizeParts[parsedSize.sizeParts.length - 1].match(
              /[a-z]+/i
            )[0];
          const newEndVal = `${newDprNum}${newEndUnit}`;
          const existingMediaQueries = parsedSize.sizeParts
            .slice(0, -1)
            .join(' ')
            .trim();
          const newMediaQueries = `${existingMediaQueries}${
            existingMediaQueries ? ' and ' : ''
          }(-webkit-min-device-pixel-ratio: ${i}) ${newEndVal}`;

          newSizes.push(newMediaQueries);
        }
      }
      newSizes.push('100vw');
      optimizedSizes = newSizes.join(', ');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console?.error(err);
  }
  return optimizedSizes;
}

function ContentStackImage(props) {
  const [isError, setIsError] = useState(false);
  const {
    legacy,
    sizes = '100vw',
    src,
    errorFallback,
    fill,
    objectFit,
    placeholder,
    style = {},
    ...rest
  } = props;

  const incrementalQualityEnabled = useSelector(
    (state) => state.featureFlags?.image_incremental_quality
  );

  const skipOptimizedSizes =
    !incrementalQualityEnabled ||
    !sizes ||
    sizes === '100vw' ||
    src?.includes?.('.svg');

  // initial set to low-res
  const [optimizedSizes, setOptimizedSizes] = useState(
    skipOptimizedSizes ? sizes : generateLowResSizes(sizes)
  );

  const willLoad = src && (fill || (props.width && props.height));

  const imgId = useId();
  // Once the image is loaded via 1DPR, we swap the sizes back to load a full res version
  useEffect(() => {
    if (sizes !== optimizedSizes) {
      const setSizesAndBackground = () => {
        requestIdleCallback(() => {
          if (sizes !== optimizedSizes) {
            // eslint-disable-next-line unicorn/prefer-query-selector
            const imgElement = document.getElementById(imgId);
            if (imgElement?.currentSrc && !legacy && placeholder !== 'blur') {
              imgElement.style.backgroundImage = `url(${imgElement.currentSrc})`;
              imgElement.style.backgroundPositionX = 'center';
              imgElement.style.backgroundPositionY = 'center';
              imgElement.style.backgroundSize = 'contain';
            }
            setOptimizedSizes(sizes);
          }
        });
      };

      if (document.readyState === 'complete') {
        setSizesAndBackground();
      } else {
        window.addEventListener('load', setSizesAndBackground);
        return () => window.removeEventListener('load', setSizesAndBackground);
      }
    }
  }, [sizes]);

  useEffect(() => {
    const imgElement = document?.getElementById(imgId);
    if (imgElement && !legacy && placeholder !== 'blur') {
      imgElement.style.backgroundImage = ``;
    }
  }, [src]);

  if (!willLoad) return null;

  if (legacy) {
    return (
      <LegacyImage
        id={imgId}
        layout={fill ? 'fill' : 'static'}
        loader={loader}
        sizes={optimizedSizes}
        src={isError && errorFallback ? errorFallback : src}
        style={style}
        objectFit={objectFit}
        placeholder={placeholder}
        onError={
          isError
            ? null
            : () => {
                setIsError(true);
              }
        }
        {...rest}
      />
    );
  }

  const styles = objectFit ? { ...style, objectFit } : style;

  return (
    <Image
      id={imgId}
      alt=""
      fill={fill}
      loader={loader}
      placeholder={placeholder}
      sizes={optimizedSizes}
      src={isError && errorFallback ? errorFallback : src}
      style={styles}
      onError={
        isError
          ? null
          : () => {
              setIsError(true);
            }
      }
      {...rest}
    />
  );
}

export default memo(ContentStackImage);
