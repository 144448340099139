import { createRef, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styles from './SearchBar.module.scss';

const searchPlaceholder = 'Search';

const SearchBar = ({ openSearchModal }) => {
  const router = useRouter();
  const [isActive, setIsActive] = useState(false);
  const inputRef = createRef();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const { search } = router.query;
    setSearchTerm(search ?? '');
  }, [router.query]);

  const handleOnChange = () => {
    const { value = '' } = inputRef?.current ?? {};
    setIsActive(value.length > 0);
    setSearchTerm('');
    if (!value) {
      return;
    }
    inputRef.current?.blur();
    document.body.focus();

    setTimeout(() => {
      openSearchModal(value);
    }, 50);
    setTimeout(() => {
      setIsActive(false);
    }, 400);
  };

  const onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleOnChange();
  };

  return (
    <Form
      id="search_bar_form"
      className={classNames(
        styles.search_forum,
        'bg-neutral-grey text-primary d-flex rounded-pill text-decoration-none',
        { [styles.search_forum_active]: isActive }
      )}
      onSubmit={onSubmit}
    >
      <Button
        aria-label="Search Products"
        className={classNames('p-0 ms-6', styles.search_icon_wrapper)}
        variant="none"
        type="button"
        onClick={onSubmit}
      >
        <div className={classNames(styles.search_icon, 'text-primary')}>
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </Button>
      <Form.Control
        ref={inputRef}
        id="search_bar_term"
        className={classNames(
          'rounded-2 bg-transparent text-primary',
          styles.search_input,
          { [styles.search_input_active]: isActive }
        )}
        placeholder={searchPlaceholder}
        type="search"
        value={searchTerm}
        onChange={handleOnChange}
        onSubmit={onSubmit}
      />
    </Form>
  );
};

export default SearchBar;
