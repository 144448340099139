import { useCallback } from 'react';

const _blank = '_blank';

const isFunc = (val) => typeof val === 'function';

// recursively find if the el click is a child of a link
const findParentWithLink = (el) => {
  if (isFunc(el?.getAttribute) && el?.getAttribute('href')) return el;
  return el.parentElement ? findParentWithLink(el.parentElement) : null;
};

/**
 /* this click handler intercepts links that were provided in rich html
 /* from contentstack. Essentially acts the same as the Link component and allows
 /* us to navigate with SPA router instead of reloading the page.
 *
 * @param {import('next/router').NextRouter} router
 */
const useRichLinkClicks = (router) =>
  useCallback(
    /** @type {React.MouseEventHandler<HTMLElement>} */
    (ev) => {
      // if any other handler has already captured this click, just return
      if (ev.isDefaultPrevented()) return;
      if (ev.isPropagationStopped()) return;
      let el;
      let href;

      if (isFunc(ev.target?.getAttribute) && ev.target?.getAttribute('href')) {
        el = ev.target;
        href = ev.target?.getAttribute('href');
      } else {
        el = findParentWithLink(ev.target);
        href = isFunc(el?.getAttribute) ? el.getAttribute('href') : null;
      }

      if (el && href) {
        ev.preventDefault();
        ev.stopPropagation();
        const target = el?.getAttribute('target');
        if (target === _blank) return window.open(href, _blank);
        if (router.isReady) {
          router.push(href);
        }
      }
    },
    [router]
  );

export default useRichLinkClicks;
