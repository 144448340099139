import React, { memo } from 'react';
import dynamic from 'next/dynamic';
import Col from 'react-bootstrap/Col';
import Link from 'components/Link';

const ModalLoyalty = dynamic(() => import('components/ModalLoyalty'), {
  ssr: false,
});

function Loyalty({ loyaltyLabel, loyaltyUrl }) {
  return (
    <>
      <Col
        xl="auto"
        className="mx-3 mx-xl-0 text-end-xl d-flex align-items-end font-geo fw-xl-600 pe-xl-0"
      >
        <Link href="/loyalty">{loyaltyLabel}</Link>
      </Col>
      <ModalLoyalty loyaltyUrl={loyaltyUrl} />
    </>
  );
}
export default memo(Loyalty);
