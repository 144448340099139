import logger from 'config/logger';
import { DUTCHIE_PURCHASE_LIMIT_KEYWORD } from 'constants/dutchie-responses';
import { RequestInstance } from 'request';
import cartSlice from 'stores/reducers/cart';
import formatStoreName from 'utils/format-store-name';
import isBrowser from 'utils/is-browser';

const TOKEN_URI = 'api/tokens';

function updateCartTracker(storeId) {
  try {
    const formattedStoreName = formatStoreName(storeId);
    RequestInstance.put(`api/carts/tracker/${formattedStoreName}`);
  } catch {
    logger.warn('Tracker - failed to update cart');
  }
}

/**
 * Set correct cart cookies in BFF.
 */
export const retrieveToken = async (storeId) => {
  try {
    await RequestInstance.post(TOKEN_URI, {
      json: { scope: formatStoreName(storeId) },
    });
  } catch (err) {
    logger.error(err, 'Failed to retrieve token');
    throw err;
  }
};

const fixSession = async (storeId) => {
  try {
    return RequestInstance.post('api/carts/session', {
      json: { scope: storeId },
    });
  } catch (err) {
    logger(err, 'Error attempting to fix store session');
    return null;
  }
};

let isRequesting = false;
/**
 * @name getCart
 * @param {string} storeId
 * @param {boolean} availability
 * @param {boolean} retry
 * @returns {Promise<void>}
 */
export const getCart =
  (storeId, availability, retry = true) =>
  async (dispatch) => {
    if (isRequesting) return;
    isRequesting = true;
    try {
      if (isBrowser && storeId) {
        const formattedStoreName = formatStoreName(storeId);

        const cartData = availability
          ? await RequestInstance.get(
              `api/carts/availability/${formattedStoreName}`
            )
          : await RequestInstance.get(`api/carts/${formattedStoreName}`);

        dispatch(
          cartSlice.actions.updateCart({
            scope: formattedStoreName,
            redirectUrl: null,
            ...cartData,
          })
        );
      }
      isRequesting = false;
    } catch (err) {
      isRequesting = false;
      logger.error(err, 'Error retrieving cart');

      if (!retry) {
        return;
      }

      await fixSession(storeId);
      await dispatch(getCart(storeId, availability, false));
    }
  };

export const updateCart =
  ({ batchSKU, storeId, SKU, quantity, shouldUpdateCartTracker = false }) =>
  async (dispatch) => {
    try {
      const formattedStoreName = formatStoreName(storeId);
      const { status, ...cartData } =
        (await RequestInstance.put(`api/carts/${formattedStoreName}`, {
          json: {
            batchSKU,
            SKU,
            quantity:
              typeof quantity === 'string'
                ? Number.parseInt(quantity, 10)
                : quantity,
          },
        })) || {};

      if (!cartData || Object.keys(cartData).length === 0) {
        await dispatch(getCart(storeId, true));
        dispatch(cartSlice.actions.setCartLimitError(null));
      } else {
        dispatch(
          cartSlice.actions.updateCart({
            scope: formattedStoreName,
            limitError: null,
            ...cartData,
          })
        );
      }
    } catch (err) {
      logger.error(err, 'Error updating cart');
      if (err.message.includes('purchase limit'))
        await dispatch(
          cartSlice.actions.setCartLimitError({
            message: err.message,
            SKU,
          })
        );

      dispatch(getCart(storeId, true));
    } finally {
      if (shouldUpdateCartTracker) {
        updateCartTracker(storeId);
      }
    }
  };

export const addItem =
  ({
    batchSKU,
    storeId,
    SKU,
    quantity,
    variation,
    shouldUpdateCartTracker = false,
  }) =>
  async (dispatch) => {
    try {
      const formattedStoreName = formatStoreName(storeId);
      const { status, ...cartData } =
        (await RequestInstance.post(`api/carts/${formattedStoreName}`, {
          json: {
            batchSKU,
            SKU,
            variation,
            quantity:
              typeof quantity === 'string'
                ? Number.parseInt(quantity, 10)
                : quantity,
          },
        })) || {};

      const itemPart = SKU.split('_')[0];

      if (!cartData || Object.keys(cartData).length === 0) {
        await dispatch(getCart(storeId));
        await dispatch(
          cartSlice.actions.updateCart({
            recentlyAdded: itemPart,
            limitError: null,
          })
        );
      } else {
        dispatch(
          cartSlice.actions.updateCart({
            scope: formattedStoreName,
            limitError: null,
            recentlyAdded: itemPart,
            ...cartData,
          })
        );
      }
    } catch (err) {
      err.SKU = SKU;
      err.quantity = quantity;
      logger.error(err, 'Error adding item to cart');
      dispatch(getCart(storeId));

      const recentlyAdded = { isError: true };
      if (err.message?.toLowerCase().includes(DUTCHIE_PURCHASE_LIMIT_KEYWORD))
        recentlyAdded.custom = err.message;

      await dispatch(cartSlice.actions.setRecentlyAdded(recentlyAdded));

      throw err;
    } finally {
      if (shouldUpdateCartTracker) {
        updateCartTracker(storeId);
      }
    }
  };
