import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';
import isBrowser from 'utils/is-browser';

export const PROMO_KEY = 'promoInfo';

const initialState = {
  code: '',
  expirationDate: '',
};

const getPromoDataFromLocalStorage = () => {
  try {
    if (isBrowser) {
      return (
        JSON.parse(window?.localStorage?.getItem(PROMO_KEY)) ?? initialState
      );
    }
    return initialState;
  } catch {
    return initialState;
  }
};

const promoSlice = createSlice({
  name: 'promo',
  initialState: getPromoDataFromLocalStorage(),
  reducers: {
    setPromo(state, action) {
      return { ...state, ...action.payload };
    },
    clearPromo() {
      return { ...initialState };
    },
  },
  extraReducers,
});

export const { setPromo, clearPromo } = promoSlice.actions;
export default promoSlice;
