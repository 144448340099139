import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Offcanvas } from 'react-bootstrap';
import Image from 'components/Image';
import Link from 'components/Link';
import { DYNAMIC_MENU_LAUNCH_STORE_SELECTOR } from 'constants/dynamic-domain';
import {
  MODAL_SELECT_STORE,
  setCurrentModal,
} from 'stores/reducers/store-selection-modal';
import constructNavigationMenu from 'utils/construct-menu';
import styles from './MenuDrawer.module.scss';

const MenuListItem = ({ customCss = {}, item, handleMenuItemClick }) => {
  const menuItemRef = useRef(null);
  return (
    <li className="py-4">
      <button
        ref={menuItemRef}
        className={classNames(
          'bg-transparent d-flex flex-row font-geo fs-14 justify-content-between w-100',
          styles.button_no_border,
          {
            'text-primary': !customCss.color,
          }
        )}
        id="menu_item_button"
        style={customCss}
        title="Menu Item"
        type="button"
        onClick={handleMenuItemClick(item, menuItemRef)}
      >
        <span className={styles.menu_text}>{item?.value?.label}</span>
        {item?.children?.length > 0 && (
          <span>
            <FontAwesomeIcon
              className={styles.variable_size_icon}
              icon={faChevronRight}
            />
          </span>
        )}
      </button>
    </li>
  );
};

export default function MenuDrawer({
  customCss = {},
  firstName,
  handleClose,
  isBasicMenu = false,
  isLoggedIn,
  menuSections,
  mobileBottomNavLogoUrl,
  selectedStoreFromState,
  shoppingBagIconUrl,
  showDrawer = false,
}) {
  const linkStyles = classNames(
    'd-flex flex-row border-0 bg-transparent fs-10 fw-600 font-geo text-primary text-uppercase my-4',
    styles.link
  );

  const dispatch = useDispatch();
  const router = useRouter();
  const [currMenu, setCurrMenu] = useState(null);

  const handleBackButtonClick = useCallback(() => {
    setCurrMenu(currMenu.parent);
  }, [currMenu]);

  const handleStoreSelectionClick = (e) => {
    e.preventDefault();
    dispatch(setCurrentModal(MODAL_SELECT_STORE));
    handleDrawerClose();
  };

  const handleMenuItemClick = (child, childRef) => async () => {
    childRef?.current?.classList.remove(styles.button_no_border);
    childRef?.current?.classList.add(styles.button_border);
    if (child?.value?.itemAction === DYNAMIC_MENU_LAUNCH_STORE_SELECTOR) {
      dispatch(setCurrentModal(MODAL_SELECT_STORE));
      handleDrawerClose();
      return;
    }
    if (child?.value?.href) {
      await router.push(child.value.href);
      handleDrawerClose();
      return;
    }
    setCurrMenu(child);
  };

  const handleDrawerClose = () => {
    let tempMenu = currMenu;
    while (tempMenu.parent) {
      tempMenu = tempMenu.parent;
    }
    setCurrMenu(tempMenu);
    handleClose();
  };

  useEffect(() => {
    if (menuSections && !currMenu) {
      const sections = constructNavigationMenu({ menuSections });
      setCurrMenu(sections);
    }
  }, []);

  return (
    <Offcanvas
      show={showDrawer}
      aria-labelledby="Mobile Menu Drawer"
      className={styles.menu_offcanvas}
      id="mobile_menu_drawer"
      name="mobile_menu_drawer"
      placement="end"
      onHide={handleDrawerClose}
    >
      <div className="d-flex flex-column h-100 w-100" style={customCss}>
        <div className="d-flex flex-row pt-4 px-6">
          <div className="d-flex align-items-center justify-content-center col-1">
            {currMenu?.parent && (
              <button
                className={classNames(
                  'border-0 bg-transparent d-flex flex-row font-geo',
                  {
                    'text-primary': !customCss.color,
                  }
                )}
                id="menu_back_button"
                style={customCss}
                title="Menu Back"
                type="button"
                onClick={handleBackButtonClick}
              >
                <span>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faChevronLeft}
                  />
                </span>
              </button>
            )}
          </div>
          <div
            className={classNames(
              'd-flex align-items-center justify-content-center col-10 fs-10 fw-600',
              {
                'text-primary': !customCss.color,
              }
            )}
            style={customCss}
          >
            {currMenu?.parent && (
              <span className={styles.menu_title}>
                {currMenu?.value?.label}
              </span>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center col-1">
            <button
              className={classNames(
                'border-0 bg-transparent d-flex flex-row font-geo px-0',
                {
                  'text-primary': !customCss.color,
                }
              )}
              id="menu_drawer_close"
              style={customCss}
              title="Menu Close"
              type="button"
              onClick={handleDrawerClose}
            >
              <span>
                <FontAwesomeIcon className={styles.icon} icon={faXmark} />
              </span>
            </button>
          </div>
        </div>
        <div className="pb-4 px-8 px-md-15 h-100 d-flex flex-column overflow-auto">
          {!currMenu?.parent && !isBasicMenu && (
            <div className="d-flex flex-column w-100 mt-4 mb-8">
              <Link
                href="/account"
                data-value="my-account-link"
                aria-label="My Account"
                className={linkStyles}
                onClick={handleDrawerClose}
              >
                <span className="me-3">
                  <Image
                    src={mobileBottomNavLogoUrl}
                    alt="Logo for my account link"
                    width={24}
                    height={24}
                  />
                </span>
                {isLoggedIn
                  ? `Hello, ${firstName}`
                  : 'Sign In | Create Account'}
              </Link>
              <Link
                href=""
                data-value="store-selection-link"
                aria-label="Select A Store"
                className={linkStyles}
                onClick={handleStoreSelectionClick}
              >
                <FontAwesomeIcon
                  className={classNames('me-3', styles.icon)}
                  icon={faMapMarkerAlt}
                />
                {selectedStoreFromState || 'Select a Store'}
              </Link>
            </div>
          )}
          <div>
            <div>
              <ul
                className={classNames('list-unstyled pb-12', {
                  'pt-10': currMenu?.parent,
                })}
                style={customCss}
              >
                {currMenu?.children?.map((child) => {
                  return (
                    <MenuListItem
                      key={`key_${child?.value?.label ?? 'menu_item'}`}
                      customCss={customCss}
                      item={child}
                      handleMenuItemClick={handleMenuItemClick}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          {!currMenu?.parent && !isBasicMenu && (
            <div className="d-flex flex-column mt-auto pt-8">
              <Link
                href="/bag"
                data-value="shopping-cart-link"
                aria-label="My Shopping Cart"
                className={linkStyles}
                onClick={handleDrawerClose}
              >
                <span className="me-3">
                  <Image
                    src={shoppingBagIconUrl}
                    alt="Logo for shopping bag"
                    width={24}
                    height={24}
                  />
                </span>
                <span className="pt-1">Bag</span>
              </Link>
              <Link
                href="/account?section=favoriteProducts"
                data-value="favorite-link"
                aria-label="My Favorite Products"
                className={linkStyles}
                onClick={handleDrawerClose}
              >
                <FontAwesomeIcon
                  className={classNames('me-3', styles.icon)}
                  icon={faHeart}
                />
                Favorites
              </Link>
            </div>
          )}
        </div>
      </div>
    </Offcanvas>
  );
}
