/**
 * Redacted fields to apply to all loggers
 * see: https://github.com/pinojs/pino/blob/master/docs/redaction.md
 *
 * note that redaction is not supported in the browser https://github.com/pinojs/pino/issues/670
 */
const redact = [
  'data.AccessToken',
  'data.AuthParameters.SRP_A',
  'data.AuthParameters.USERNAME',
  'data.birthdate',
  'data.birthday',
  'data.ChallengeResponses.PASSWORD_CLAIM_SECRET_BLOCK',
  'data.ChallengeResponses.PASSWORD_CLAIM_SIGNATURE',
  'data.confirm_password',
  'data.ConfirmationCode',
  'data.Password',
  'data.password',
  'data.properties.$email',
  'data.Username',
  'headers.access_token',
  'headers.api_key',
  'req.headers.authorization',
  'req.headers.cookie',
  'res.headers["content-security-policy"]',
  'res.headers["set-cookie"]',
  'response.access_token',
  'response.AuthenticationResult.AccessToken',
  'response.AuthenticationResult.IdToken',
  'response.AuthenticationResult.RefreshToken',
  'response.ChallengeParameters.SALT',
  'response.ChallengeParameters.SECRET_BLOCK',
  'response.ChallengeParameters.SRP_B',
  'response.data.access_token',
  'response.headers["content-security-policy"]',
  'response.headers["feature-policy"]',
];

export default redact;
