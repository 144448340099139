import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import Link from 'components/Link';
import { sendMenuClickEvent } from 'utils/metrics';
import styles from './MenuBar.module.scss';

const scrollIncrement = 125;
const scrollRight = () => {
  const container = document.querySelector('#menuBarContainer');
  if (!container) return;
  container.scrollLeft -= scrollIncrement;
};

const scrollLeft = () => {
  const container = document.querySelector('#menuBarContainer');
  if (!container) return;
  container.scrollLeft += scrollIncrement;
};

const hideLeftButton = () => {
  const scrollButton = document.querySelector('#scrollRight');
  if (!scrollButton) return;
  scrollButton.classList.add(styles.hide);
};

const showLeftButton = () => {
  const scrollButton = document.querySelector('#scrollRight');
  if (!scrollButton) return;
  scrollButton.classList.remove(styles.hide);
};

const hideRightButton = () => {
  const scrollButton = document.querySelector('#scrollLeft');
  if (!scrollButton) return;
  scrollButton.classList.add(styles.hide);
};

const showRightButton = () => {
  const scrollButton = document.querySelector('#scrollLeft');
  if (!scrollButton) return;
  scrollButton.classList.remove(styles.hide);
};

const checkScrollPosition = () => {
  const container = document.querySelector('#menuBarContainer');
  if (!container) return;
  const { offsetWidth, scrollLeft, scrollWidth } = container;
  if (scrollLeft === 0) {
    hideLeftButton();
  } else {
    showLeftButton();
  }

  if (scrollWidth - offsetWidth - scrollLeft <= 10) {
    hideRightButton();
  } else {
    showRightButton();
  }
};

const checkAlignment = () => {
  const container = document.querySelector('#menuBarContainer');
  if (!container) return;
  const { classList, offsetWidth, scrollWidth } = container;

  if (scrollWidth - offsetWidth === 0) {
    classList.add(styles.centerAlignMenu);
  } else {
    classList.remove(styles.centerAlignMenu);
  }
};

export function MenuBar({ menuBarItemsSections }) {
  const router = useRouter();
  const [activeUrl, setActiveUrl] = useState();

  useEffect(() => {
    const { asPath } = router;
    if (asPath !== activeUrl) {
      setActiveUrl(asPath);
    }
  }, [router]);

  useEffect(() => {
    const handleScrollEvent = () => {
      checkScrollPosition();
    };

    const handleResizeEvent = () => {
      checkScrollPosition();
      checkAlignment();
    };

    window.addEventListener('resize', handleResizeEvent);
    document
      .querySelector('#menuBarContainer')
      .addEventListener('scroll', handleScrollEvent);

    checkScrollPosition();

    return () => {
      window.removeEventListener('resize', handleResizeEvent);
      if (document.querySelector('#menuBarContainer')) {
        document
          .querySelector('#menuBarContainer')
          .removeEventListener('scroll', handleScrollEvent);
      }
    };
  }, []);

  return (
    <section className="w-100">
      <div className="d-flex flex-row">
        <Button
          className={classNames(
            'bg-transparent text-primary fs-9 m-2 px-0',
            styles.hide
          )}
          id="scrollRight"
          type="button"
          title="scroll right"
          onClick={scrollRight}
        >
          <span className={classNames('d-inline-block', styles.arrow)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        </Button>
        <div
          id="menuBarContainer"
          className={classNames(
            'w-100 py-5',
            styles.menuBarContainer,
            styles.centerAlignMenu
          )}
        >
          {menuBarItemsSections
            ?.filter((section) => section.item?.title && section.item?.href)
            .map((section) => {
              const {
                item: { title, href },
              } = section;
              return (
                <Link
                  key={title}
                  className={classNames(
                    'fs-8 text-uppercase fw-600 px-7 px-lg-12 px-xl-15',
                    styles.menuItem,
                    { [styles.activeMenuItem]: href === activeUrl }
                  )}
                  data-value={`menu-option-${title}`}
                  href={href}
                  onClick={() => {
                    const itemId = title.toLowerCase().replaceAll(' ', '_');
                    sendMenuClickEvent(`button_${itemId}`);
                  }}
                >
                  <span className="mr-2">{title}</span>
                </Link>
              );
            })}
        </div>
        <Button
          className={classNames(
            'bg-transparent text-primary fs-9 m-2 px-0',
            styles.hide
          )}
          id="scrollLeft"
          title="scroll left"
          type="button"
          onClick={scrollLeft}
        >
          <span className={classNames('d-inline-block', styles.arrow)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </Button>
      </div>
    </section>
  );
}

export default MenuBar;
