import logger from 'config/logger';
import ageGateSlice, { AGE_RESTRICTION_KEY } from 'stores/reducers/age-gate';

export const setAgeRestrictionAcceptance = (bool) => (dispatch) => {
  try {
    window.localStorage.setItem(AGE_RESTRICTION_KEY, JSON.stringify(bool));
    dispatch(ageGateSlice.actions.setAgeRestriction(bool));
  } catch (err) {
    logger.error(err);
  }
};
