import ky from 'ky';
import isBrowser from 'utils/is-browser';
import redact from './redact';

const level = process.env.LOG_LEVEL || 'info';

// https://getpino.io/#/docs/help?id=mapping-pino-log-levels-to-google-cloud-logging-stackdriver-serverity-levels
const severityByPinoLevel = {
  trace: 'DEBUG',
  debug: 'DEBUG',
  info: 'INFO',
  warn: 'WARNING',
  error: 'ERROR',
  fatal: 'CRITICAL',
};
const options = {
  messageKey: 'message',
  formatters: {
    level(label, number) {
      return {
        severity: severityByPinoLevel[label] || severityByPinoLevel.info,
        level: number,
      };
    },
  },
  level,
  redact,
  browser: {
    transmit: {
      /**
       * We only transmit for this log level
       */
      level: process.env.NEXT_PUBLIC_LOG_LEVEL || 'error',
      async send(level, logEvent) {
        if (isBrowser && level && logEvent)
          ky.post('/api/log', {
            json: {
              level,
              logEvent,
            },
            // eslint-disable-next-line no-console
          }).catch(console.error);
      },
    },
  },
};

export default options;
