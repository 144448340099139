import { faHeart as emptyFaHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as solidFaHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

export default function FavoriteProductButton({
  className,
  isFavorite,
  handleButtonClick,
  label = 'Toggle Favorite Product Button',
}) {
  return (
    <Button
      aria-label={label}
      className="p-0"
      variant="none"
      type="button"
      onClick={handleButtonClick}
    >
      <div className={className}>
        <FontAwesomeIcon
          className={isFavorite ? 'text-secondary' : 'text-primary'}
          icon={isFavorite ? solidFaHeart : emptyFaHeart}
        />
      </div>
    </Button>
  );
}
