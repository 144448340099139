import { createSlice, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { parseCookies, setCookie } from 'nookies';
import isBrowser from 'utils/is-browser';

export const SLICE_NAME = 'storeSelection';

const getStoreDataFromLocalStorage = () => {
  try {
    if (isBrowser) {
      const fromLocalStorage = JSON.parse(
        window.localStorage.getItem('storeInfo')
      );
      const { store: fromCookies } = parseCookies();

      if (fromLocalStorage && !fromCookies) {
        setCookie(null, 'store', fromLocalStorage.title, {
          maxAge: 365 * 24 * 60 * 60,
          path: '/',
        });
      }

      return fromLocalStorage?.title || fromCookies;
    }
    return null;
  } catch {
    return null;
  }
};

export const initialState = getStoreDataFromLocalStorage() || '';

const storeSelectionSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setStoreSelection(state, action) {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(createAction(HYDRATE), (state, action) => {
      if (action.payload.storeSelection === '' && state !== '') {
        return state;
      }
      return action.payload.storeSelection;
    });
  },
});

export default storeSelectionSlice;
