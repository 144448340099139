import React from 'react';
import { withRouter } from 'next/router';
import logger from 'config/logger';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    if (this.state.hasError && prevProps.router !== this.props.router) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logger.error({ error, errorInfo }, 'Unexpected client error occurred');
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
