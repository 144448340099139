import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const initialState = {
  isShowing: false,
  pendingStoreName: '',
  pendingMenuSelection: '',
  navigate: false,
  navigateTo: '',
  navigateToOnCancel: '',
  pickupOrDelivery: '',
  type: '',
  addToBag: {},
};

const storeSelectionSlice = createSlice({
  name: 'cartWarningModal',
  initialState,
  reducers: {
    showCartWarningModal(state, action) {
      return { ...state, isShowing: true, ...action.payload };
    },
    hideCartWarningModal() {
      return initialState;
    },
  },
  extraReducers,
});

export const { showCartWarningModal, hideCartWarningModal } =
  storeSelectionSlice.actions;
export default storeSelectionSlice;
