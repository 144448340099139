/**
 * Returns false if object has no own enumerable properties
 * and true if it does.
 *
 * @param {*} obj
 * @returns {boolean}
 */
const isEmpty = (obj = {}) => obj === null || Object.keys(obj)?.length === 0;

export default isEmpty;
