import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'components/Image';
import Link from 'components/Link';
import MenuDrawer from 'components/MenuDrawer';
import StoreAndMenu from 'components/StoreAndMenu';
import {
  DYNAMIC_MENU_HEADER_BUTTON,
  DYNAMIC_MENU_HEADER_DISPLAY_OVERLAY,
  DYNAMIC_MENU_HEADER_DISPLAY_STACKED,
  DYNAMIC_MENU_LAUNCH_STORE_SELECTOR,
  DYNAMIC_MENU_LEFT_POSITION,
  DYNAMIC_MENU_RIGHT_POSITION,
} from 'constants/dynamic-domain';
import useIsSmallViewport from 'hooks/useIsSmallViewport';
import {
  MODAL_SELECT_STORE,
  setCurrentModal,
} from 'stores/reducers/store-selection-modal';
import styles from './DynamicDomainHeader.module.scss';

function renderHeaderCta({
  backgroundColor,
  dispatch,
  href,
  itemAction,
  label,
  textColor,
  type,
}) {
  const isButton = type === DYNAMIC_MENU_HEADER_BUTTON;
  const cssStyle = { color: `${textColor}` };
  if (isButton) {
    cssStyle.background = backgroundColor;
  }

  return itemAction === DYNAMIC_MENU_LAUNCH_STORE_SELECTOR ? (
    <Button
      key={label}
      className={classNames(
        'fs-10 fw-600 font-geo text-uppercase mx-5',
        styles.letter_spacing,
        {
          'btn px-6 py-5': isButton,
        }
      )}
      style={cssStyle}
      variant="none"
      type="button"
      onClick={() => dispatch(setCurrentModal(MODAL_SELECT_STORE))}
    >
      {label}
    </Button>
  ) : (
    <Link
      key={label}
      className={classNames(
        'fs-10 fw-600 font-geo text-uppercase mx-5',
        styles.letter_spacing,
        {
          'btn px-6 py-5': isButton,
        }
      )}
      style={cssStyle}
      href={href}
    >
      {label}
    </Link>
  );
}

function renderMobileMenuButton({ toggleMobileMenu }) {
  return (
    <Button
      aria-label="Menu"
      variant="none"
      type="button"
      className="d-xl-none pt-8 px-2"
      onClick={toggleMobileMenu}
    >
      <span
        className={classNames(
          'd-inline-block p-0 text-primary',
          styles.menu_open
        )}
      >
        <FontAwesomeIcon icon={faBars} />
      </span>
    </Button>
  );
}

function generateCustomCss({ backgroundColor, textColor }) {
  const customCss = {};
  if (backgroundColor) {
    customCss.background = backgroundColor;
  }
  if (textColor) {
    customCss.color = textColor;
  }
  return customCss;
}

function DynamicDomainHeader({
  desktopBackgroundColor,
  desktopMenuItems,
  displayPosition,
  logoDescription,
  logoUrl,
  mobileBackgroundColor,
  mobileTextColor,
  mobileMenuItems,
  mobileMenuPosition,
  storeSelectorContent,
}) {
  const dispatch = useDispatch();
  const isSmallViewport = useIsSmallViewport();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = useCallback(() => {
    setShowMobileMenu((val) => !val);
  }, []);

  useEffect(() => {
    // if were mobile and the nav bar is uncollapsed, turn off scrolling on the underlying content
    if (showMobileMenu) {
      document.querySelector('body').classList.add('mobile-nav-no-scroll');
    } else {
      document.querySelector('body').classList.remove('mobile-nav-no-scroll');
    }

    // if the menu is open and we switch to desktop view then ensure that the menu collapsed state is reset
    // we need this for the sticky behavior because there is no way in css for the sticky container to know
    // the collapsed state of the navbar and turn on and off appropriately on a screen resize
    if (!isSmallViewport && showMobileMenu) {
      toggleMobileMenu();
    }
  }, [isSmallViewport, showMobileMenu, toggleMobileMenu]);

  return (
    <>
      <header
        className={classNames({
          [styles.sticky_transform]:
            displayPosition === DYNAMIC_MENU_HEADER_DISPLAY_STACKED,
          [styles.absolute]:
            displayPosition === DYNAMIC_MENU_HEADER_DISPLAY_OVERLAY,
        })}
        style={{
          background:
            displayPosition === DYNAMIC_MENU_HEADER_DISPLAY_STACKED
              ? desktopBackgroundColor || '#ffffff'
              : '',
        }}
      >
        <div id="header_container">
          <div
            className={classNames(styles.navbar_container, {
              [styles.scroll]: showMobileMenu,
            })}
          >
            <Nav
              aria-label="header navigation"
              role="navigation"
              className={styles.navbar_content}
            >
              <Container fluid className="px-3 py-8">
                <div className="position-relative" id="basic-navbar-nav">
                  <div className="row align-items-center mx-4">
                    <div className="col-2 col-xl-4">
                      <div className="d-none d-xl-flex flex-row justify-content-start align-items-center">
                        {desktopMenuItems
                          .filter(
                            (item) =>
                              item.position === DYNAMIC_MENU_LEFT_POSITION ||
                              item.position === ''
                          )
                          .map(
                            ({
                              background_color: backgroundColor,
                              href,
                              item_action: itemAction,
                              label,
                              text_color: textColor,
                              type,
                            }) => {
                              return renderHeaderCta({
                                backgroundColor,
                                dispatch,
                                href,
                                itemAction,
                                label,
                                textColor,
                                type,
                              });
                            }
                          )}
                      </div>
                      {mobileMenuPosition === DYNAMIC_MENU_LEFT_POSITION && (
                        <div className="d-flex d-xl-none flex-row justify-content-between align-items-center">
                          {renderMobileMenuButton({ toggleMobileMenu })}
                        </div>
                      )}
                    </div>
                    <div className="col-8 col-xl-4">
                      <div className="d-flex flex-row justify-content-center align-items-center py-0 py-xl-6">
                        <Navbar.Brand
                          className={classNames(
                            'd-inline-block mx-0 p-0',
                            styles.logo_wrapper
                          )}
                        >
                          <Link
                            href="/"
                            data-value="dynamic-home-page-logo"
                            aria-label="Lume Cannabis Company"
                            className="d-inline-block w-100"
                          >
                            <Image
                              alt={logoDescription}
                              className="w-100 h-auto"
                              height={53}
                              src={logoUrl}
                              width={200}
                            />
                          </Link>
                        </Navbar.Brand>
                      </div>
                    </div>
                    <div className="col-2 col-xl-4">
                      <div className="d-none d-xl-flex flex-row-reverse justify-content-start align-items-center">
                        {desktopMenuItems
                          .filter(
                            (item) =>
                              item.position === DYNAMIC_MENU_RIGHT_POSITION
                          )
                          .map(
                            ({
                              background_color: backgroundColor,
                              href,
                              item_action: itemAction,
                              label,
                              text_color: textColor,
                              type,
                            }) => {
                              return renderHeaderCta({
                                backgroundColor,
                                dispatch,
                                href,
                                itemAction,
                                label,
                                textColor,
                                type,
                              });
                            }
                          )}
                      </div>
                      {mobileMenuPosition === DYNAMIC_MENU_RIGHT_POSITION && (
                        <div className="d-flex d-xl-none flex-row-reverse justify-content-between align-items-center">
                          {renderMobileMenuButton({ toggleMobileMenu })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Container>
            </Nav>
          </div>
        </div>
      </header>
      <MenuDrawer
        isBasicMenu
        customCss={generateCustomCss({
          textColor: mobileTextColor,
          backgroundColor: mobileBackgroundColor,
        })}
        handleClose={toggleMobileMenu}
        menuSections={mobileMenuItems}
        showDrawer={showMobileMenu}
      />
      <StoreAndMenu
        hideTrigger
        filterMenuOptions={['Recreational', 'Medical']}
        storeSelectionContent={storeSelectorContent}
      />
    </>
  );
}

export default DynamicDomainHeader;
