import { memo } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Image from 'components/Image';
import Link from 'components/Link';
import styles from './HeaderBrand.module.scss';

const HeaderBrand = memo(function ({ unifiedLogoDescription, unifiedLogoUrl }) {
  return (
    <Row className="ms-xl-5 me-xl-4 py-6">
      <Navbar.Brand
        className={`d-inline-block me-0 ms-12 ms-sm-16 ms-xl-0 p-0 ${styles.wrapper}`}
      >
        <Link
          href="/"
          data-value="lume-home-page-navigation-block"
          aria-label="Lume Cannabis Company"
          className="d-inline-block w-100 pt-xl-6"
        >
          <Image
            alt={unifiedLogoDescription}
            className="w-100 h-auto"
            height={53}
            src={unifiedLogoUrl}
            width={200}
          />
        </Link>
      </Navbar.Brand>
    </Row>
  );
});

export default HeaderBrand;
