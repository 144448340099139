class MenuNode {
  constructor(value) {
    this.value = value;
    this.children = [];
    this.parent = null;
  }
}

function createListOfNodes(items = [], prevNode) {
  const numberOfItems = items?.length ?? 0;
  if (numberOfItems === 0) {
    return null;
  }

  for (let i = 0; i < numberOfItems; i++) {
    const {
      item_action: itemAction,
      root_item: { title, href },
      subsections,
    } = items[i];

    const node = new MenuNode({ label: title, href, itemAction });
    prevNode.children.push(node);
    node.parent = prevNode;

    createListOfNodes(subsections, node);
  }
}

export default function constructNavigationMenu({ menuSections }) {
  const rootNode = new MenuNode({
    label: 'All',
    href: '',
    itemAction: undefined,
  });
  createListOfNodes(menuSections, rootNode);
  return rootNode;
}
