import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isInitialState: true,
    isLoggedIn: false,
    userInfo: {
      dateOfBirth: '',
      deliveryAddress: '',
      email: '',
      emailVerified: '',
      firstName: '',
      identityPlatformId: '',
      isEnrolledMarketing: false,
      isEnrolledOrderCommunication: false,
      klaviyoId: '',
      klaviyoProperties: {},
      lastName: '',
      location: '',
      phoneNumber: '',
      preferredStore: '',
      springBigId: '',
      userSchemaVersion: '',
      zipCode: '',
    },
  },
  reducers: {
    updateUserInformation(state, action) {
      return { state, ...action.payload };
    },
  },
  extraReducers,
});

export default userSlice;
