import { useEffect, useState } from 'react';
import Head from 'next/head';
import Layout from 'components/Layout';
import { RequestInstance } from 'request';
import styles from './ErrorPage.module.scss';

export default function ErrorPage() {
  const [headerFooter, setHeaderFooter] = useState(null);
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    RequestInstance.get('api/content/header-footer')
      .then((data) => {
        setHeaderFooter(data.header);
      })
      .catch(() => {});
    RequestInstance.get('api/content/unknown_error_content')
      .then((data) => {
        setPageContent(data[0]);
      })
      .catch(() => {
        setPageContent({
          header: 'Oops...',
          link: 'Show me the light',
          subheading: 'Something unexpected happened.',
        });
      });
  }, []);

  return (
    <>
      <Head>Error</Head>
      <Layout header={headerFooter?.header} footer={headerFooter?.footer}>
        {pageContent ? (
          <div className="bg-warning d-flex flex-column align-items-center vh-100 w-100 text-center">
            <h1 className={`font-freight fw-900 text-white ${styles.title}`}>
              {pageContent.header}
            </h1>
            <h2
              className="font-acumin fs-13 mt-24 text-white"
              data-value="error-message"
            >
              {pageContent.subheading}
            </h2>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
              className="border border-2 border-primary font-geo fs-7 fw-600 px-8 py-4 mt-16 text-uppercase"
              href="/"
              data-value="error-cta-link"
            >
              {pageContent.link}
            </a>
          </div>
        ) : null}
      </Layout>
    </>
  );
}
