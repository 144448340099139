/* eslint-disable camelcase */
/**
 * An abstraction for sending analytics data.
 * If instrumenting new events for GTM, first check this reference
 * https://developers.google.com/tag-platform/gtagjs/reference/events#login
 * To see if an OOB recommended event exists.
 */

import isBrowser from './is-browser';

const PAGE_VIEW = 'virtual_page_view';

export const pushEvent = async (...args) => {
  if (isBrowser && window.dataLayer) {
    window.dataLayer.push(...args);
    return true;
  }
  return false;
};

export const sendEvent = async (eventLabel, data = {}) => {
  return pushEvent({
    event: eventLabel,
    ...data,
  });
};

export const sendEcommerceEvent = async (eventLabel, ecommerceData) => {
  await pushEvent({ ecommerce: null });
  return sendEvent(eventLabel, { ecommerce: ecommerceData });
};

export const sendPageView = async (pageTitle, data = {}) => {
  return sendEvent(PAGE_VIEW, {
    virtual_page_title: pageTitle,
    ...data,
  });
};

export const setMetricsData = (data) => sendEvent('set_data', data);

export const sendSearchMetricData = (searchTerm = '') =>
  sendEvent('search', { search_term: searchTerm });

export const sendLinkClickEvent = (parentId, linkId, linkText, url) =>
  sendEvent('link_click', {
    parent_id: parentId,
    link_id: linkId,
    link_text: linkText,
    url,
  });

export const sendMenuClickEvent = (item_id) =>
  sendEvent('menu_click', { item_id });

export const sendDrawerClickEvent = () => sendEvent('open_discount_drawer');
