import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import cartSlice from 'stores/reducers/cart';

const AddToBagModal = dynamic(import('components/AddToBagModal'), {
  ssr: false,
});

const AddToBagListener = memo(() => {
  const { recentlyAdded } = useSelector(({ cart }) => cart);

  const dispatch = useDispatch();
  const clearRecentlyAddedSKU = useCallback(() => {
    dispatch(cartSlice.actions.resetRecentlyAdded());
    dispatch(cartSlice?.actions?.setCartLimitError(null));
  }, [dispatch]);

  if (!recentlyAdded) {
    return null;
  }

  if (recentlyAdded.isError) {
    return (
      <AddToBagModal
        isError
        customMessage={recentlyAdded.custom}
        onClose={clearRecentlyAddedSKU}
      />
    );
  }
});

export default AddToBagListener;
