import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const modalOpenSlice = createSlice({
  name: 'modalOpen',
  initialState: false,
  reducers: {
    setModalOpen(_, action) {
      return action.payload;
    },
  },
  extraReducers,
});

export default modalOpenSlice;
