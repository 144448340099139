import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const initialState = {
  isShowing: false,
};

const loyaltyModalSlice = createSlice({
  name: 'loyaltyModal',
  initialState,
  reducers: {
    showLoyaltyModal(state, action) {
      return { ...state, isShowing: true, ...action.payload };
    },
    hideLoyaltyModal() {
      return initialState;
    },
  },
  extraReducers,
});

export const { showLoyaltyModal, hideLoyaltyModal } = loyaltyModalSlice.actions;
export default loyaltyModalSlice;
