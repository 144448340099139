import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const initialState = {
  scope: '',
  id: '',
  totalPrice: {
    centAmount: 0,
    currencyCode: 'USD',
    fractionDigits: 2,
  },
  lineItems: [],
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    changeScope(_, action) {
      return { ...initialState, ...action.payload };
    },
    updateOrder(state, action) {
      return { ...state, ...action.payload };
    },
    resetOrder() {
      return { ...initialState };
    },
  },
  extraReducers,
});

export default orderSlice;
