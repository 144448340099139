import { createSlice, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { parseCookies, setCookie } from 'nookies';
import isBrowser from 'utils/is-browser';

const storeKey = 'selectedMenu';
const RECREATIONAL = 'recreational';
const MED_OR_REC = 'medOrRec';

const getStoreMenu = () => {
  try {
    if (isBrowser) {
      const fromLocalStorage = window.localStorage.getItem(MED_OR_REC);
      const { menu: fromCookies } = parseCookies();
      const selectedMenu = fromLocalStorage || fromCookies || RECREATIONAL;

      setCookie(null, 'menu', selectedMenu, {
        maxAge: 365 * 24 * 60 * 60,
        path: '/',
      });
      window.localStorage.setItem(MED_OR_REC, selectedMenu);

      return selectedMenu;
    }
    return RECREATIONAL;
  } catch {
    return RECREATIONAL;
  }
};

const selectedMenuSlice = createSlice({
  name: storeKey,
  initialState: getStoreMenu(),
  reducers: {
    selectMenu(state, action) {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(createAction(HYDRATE), (state, action) => {
      return state || action.payload[storeKey];
    });
  },
});

export default selectedMenuSlice;
