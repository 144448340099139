import { createSlice, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const getStoreDataFromLocalStorage = () => {
  try {
    return JSON.parse(window?.localStorage?.getItem('storeInfo'));
  } catch {
    return null;
  }
};

const selectedStoreInformationSlice = createSlice({
  name: 'selectedStoreInformation',
  initialState: getStoreDataFromLocalStorage(),
  reducers: {
    setStoreInformation(_, action) {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(createAction(HYDRATE), (state, action) => {
      return state || action.payload;
    });
  },
});

export default selectedStoreInformationSlice;
