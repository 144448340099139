import { useState, useEffect } from 'react';

function useIsSmallViewport() {
  const [isSmallViewport, setIsSmallViewport] = useState();

  useEffect(() => {
    function handleResize() {
      setIsSmallViewport(window.innerWidth < 1200);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallViewport;
}

export default useIsSmallViewport;
