import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Image from 'components/Image';
import styles from './ShoppingBagIcon.module.scss';

const ShoppingBagIcon = ({
  className,
  openShoppingBag,
  shoppingBagIconUrl,
}) => {
  const cartQuantity = useSelector((state) =>
    state.cart?.lineItems?.reduce(
      (quantity, lineItem) =>
        quantity + Number.parseInt(lineItem?.quantity, 10),
      0
    )
  );
  const areString = cartQuantity === 1 ? 'is' : 'are';
  const itemsString = cartQuantity === 1 ? 'item' : 'items';
  const ariaLabel = `My Bag. There ${areString} ${cartQuantity} ${itemsString} in your bag.`;
  const classes = classNames(
    'd-flex align-items-center border-0 bg-transparent text-primary text-uppercase',
    className,
    styles.bagWrapper
  );

  const bagIcon = (
    <div className={styles.bag}>
      <Image
        fill
        alt="Shopping bag icon"
        objectFit="contain"
        src={shoppingBagIconUrl}
        sizes="20vw"
      />
      {cartQuantity > 0 && (
        <span className="align-self-center mt-2 fs-7 fs-xl-5 mt-xl-2">
          {cartQuantity}
        </span>
      )}
    </div>
  );

  return (
    <button
      aria-label={ariaLabel}
      className={classes}
      type="button"
      onClick={openShoppingBag}
    >
      {bagIcon}
    </button>
  );
};

export default ShoppingBagIcon;
