import React from 'react';
import PropTypes from 'prop-types';

const MapIcon = ({ color, testId }) => (
  <svg
    data-testid={testId}
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9999 1.83325C7.45242 1.83325 4.58325 4.70242 4.58325 8.24992C4.58325 13.0624 10.9999 20.1666 10.9999 20.1666C10.9999 20.1666 17.4166 13.0624 17.4166 8.24992C17.4166 4.70242 14.5474 1.83325 10.9999 1.83325ZM10.9999 10.5416C9.73492 10.5416 8.70825 9.51492 8.70825 8.24992C8.70825 6.98492 9.73492 5.95825 10.9999 5.95825C12.2649 5.95825 13.2916 6.98492 13.2916 8.24992C13.2916 9.51492 12.2649 10.5416 10.9999 10.5416Z"
      fill={color}
    />
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x={4}
      y={1}
      width={14}
      height={20}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 1.83325C7.45242 1.83325 4.58325 4.70242 4.58325 8.24992C4.58325 13.0624 10.9999 20.1666 10.9999 20.1666C10.9999 20.1666 17.4166 13.0624 17.4166 8.24992C17.4166 4.70242 14.5474 1.83325 10.9999 1.83325ZM10.9999 10.5416C9.73492 10.5416 8.70825 9.51492 8.70825 8.24992C8.70825 6.98492 9.73492 5.95825 10.9999 5.95825C12.2649 5.95825 13.2916 6.98492 13.2916 8.24992C13.2916 9.51492 12.2649 10.5416 10.9999 10.5416Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect width={22} height={22} />
    </g>
  </svg>
);

MapIcon.propTypes = {
  color: PropTypes.string,
};

export default MapIcon;
