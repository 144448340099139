import { createSlice, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: {},
  reducers: {
    setFlags(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(createAction(HYDRATE), (state, action) => {
      return {
        ...state,
        ...action.payload.featureFlags,
      };
    });
  },
});

export default featureFlagsSlice;
