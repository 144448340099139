import Link from 'next/link';

function LumeLink({ children, ...props }) {
  return (
    <Link prefetch={false} {...props}>
      {children}
    </Link>
  );
}

export default LumeLink;
