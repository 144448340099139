import { memo } from 'react';
import { useRouter } from 'next/router';
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import Image from 'components/Image';
import Link from 'components/Link';
import styles from './Footer.module.scss';

const onChatClick = () => {
  /* eslint-disable no-undef */
  if (zE) {
    zE('messenger', 'open');
  }
  /* eslint-enable no-undef */
};
export default memo(function ({
  companyInformation,
  copyRight,
  customerSupport,
  facebookLink,
  fireflyLogoIcon,
  fireflyLogoDescription,
  logoTextDescription,
  logoTextIcon,
  instagramLink,
  legalLinks,
  socialMediaLabel,
  zendeskChatUrl,
}) {
  const router = useRouter();

  // Determines bottom padding, used to give space for sticky checkout CTA
  const isBag = router.route === '/bag';
  return (
    <footer
      className={classNames(`px-0 py-10 p-xl-32 text-white`, styles.container, {
        'pb-50 pb-md-10': isBag,
      })}
    >
      <nav
        aria-label="footer navigation"
        className="ms-0 ms-xl-18 p-0 px-xl-3 py-xl-6"
      >
        <div
          className={`d-flex flex-column flex-xl-row mb-32 justify-content-around justify-content-xl-between ${styles.top_row}`}
        >
          <div className="d-none d-xl-flex flex-column justify-content-center text-center">
            <div>
              <Image
                alt={fireflyLogoDescription}
                height={82}
                src={fireflyLogoIcon}
                width={82}
              />
            </div>
            <div className="pt-7">
              <Image
                alt={logoTextDescription}
                height={70}
                src={logoTextIcon}
                width={167}
              />
            </div>
          </div>
          <div className="d-flex d-xl-none flex-column justify-content-center">
            <div>
              <Image
                alt={fireflyLogoDescription}
                height={67}
                src={fireflyLogoIcon}
                width={66}
              />
            </div>
            <div className="pt-7">
              <Image
                alt={logoTextDescription}
                height={56}
                src={logoTextIcon}
                width={133}
              />
            </div>
          </div>
          <div
            className={`d-flex flex-column flex-xl-row mt-xl-auto ${styles.links}`}
          >
            <div className="fs-7">
              <div
                className={`fw-bold pb-4 text-uppercase ${styles.section_header}`}
                role="heading"
                aria-level={3}
              >
                {customerSupport.contact_label}
              </div>
              <div className={`d-flex flex-column ${styles.contact_links}`}>
                {customerSupport.links.map((link) => {
                  return link.label?.includes('Chat') && zendeskChatUrl ? (
                    <div key={link.label} className="mb-8">
                      <Button
                        type="button"
                        title="Chat with customer service"
                        className="fs-7 align-top"
                        variant="secondary"
                        onClick={onChatClick}
                      >
                        {link.link.title}
                      </Button>
                    </div>
                  ) : (
                    <div key={link.label} className="mb-8">
                      <span>{`${link.label}: `}</span>
                      <a
                        aria-label="Send us a text message."
                        className="text-white"
                        href={link.link.href}
                      >
                        {link.link.title}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`fs-7 ${styles.company_section}`}>
              <div
                className={`fw-bold pb-4 text-uppercase ${styles.section_header}`}
                role="heading"
                aria-level={3}
              >
                {companyInformation.company_information_label}
              </div>
              <div className={`d-flex flex-column ${styles.company_links}`}>
                {companyInformation.links.map((link) => {
                  const isExternal = link.link.href.includes('http');
                  return link.label ? (
                    <div
                      key={link.label}
                      className="d-flex flex-row flex-xl-column m-auto m-xl-0"
                    >
                      <span>{link.label}&nbsp;</span>
                      <a
                        className="text-white"
                        href={link.link.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {link.link.title}
                      </a>
                    </div>
                  ) : (
                    <Link
                      key={link.link.title}
                      href={link.link.href}
                      target={isExternal ? '_blank' : null}
                      rel={isExternal ? 'noreferrer noopener' : null}
                      className="text-white mb-8"
                    >
                      {link.link.title}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className={`fs-7 d-flex flex-column ${styles.social}`}>
              <div
                className={`fw-bold pb-15 text-uppercase ${styles.section_header}`}
                role="heading"
                aria-level={3}
              >
                {socialMediaLabel}
              </div>
              <div className="d-flex flex-row justify-content-between">
                <a
                  aria-label="Visit Lume Instagram"
                  href={instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    className={classNames(
                      'd-inline-block text-white',
                      styles.social_icon
                    )}
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </span>
                </a>
                <a
                  aria-label="Visit Lume Facebook"
                  href={facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    className={classNames(
                      'd-inline-block text-white',
                      styles.social_icon
                    )}
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-xl-row fs-7 fs-xl-6 text-center text-xl-left">
          <div className="pb-3 pb-xl-0 pe-xl-16">{copyRight}</div>
          <div>
            {legalLinks.link.map((link, idx) => {
              return (
                <span key={link.title}>
                  <Link href={link.href} className="text-white">
                    {link.title}
                  </Link>
                  {idx < legalLinks.link.length - 1 ? ' | ' : null}
                </span>
              );
            })}
          </div>
        </div>
      </nav>
      <div id="lume-next-id" />
    </footer>
  );
});
