import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const productFetchStatusSlice = createSlice({
  name: 'productFetchStatus',
  initialState: '',
  reducers: {
    setProductFetchStatus(state, action) {
      return action.payload;
    },
  },
  extraReducers,
});

export default productFetchStatusSlice;
