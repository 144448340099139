import {
  faFacebookSquare,
  faInstagram,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Nav from 'react-bootstrap/Nav';
import Image from 'components/Image';
import styles from './DynamicDomainFooter.module.scss';

function renderSocialMediaCta({
  color,
  href,
  iconLookup = {},
  spacing,
  title,
  type,
}) {
  const socialIcon = iconLookup[type?.toLowerCase()];

  if (!socialIcon) {
    return;
  }

  return (
    <div key={title} className={classNames(spacing)}>
      <a
        aria-label={title}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span
          className={classNames('d-inline-block', styles.social_icon)}
          style={{ color: color || '#FFFFFF' }}
        >
          <FontAwesomeIcon icon={socialIcon} />
        </span>
      </a>
    </div>
  );
}

function generateCustomCss({ backgroundColor, textColor }) {
  const customCss = {};
  if (backgroundColor) {
    customCss.background = backgroundColor;
  }
  if (textColor) {
    customCss.color = textColor;
  }
  return customCss;
}

function DynamicDomainFooter({
  desktopBackgroundColor,
  desktopTextColor,
  footerText,
  logoTitle,
  logoUrl,
  socials,
  mobileBackgroundColor,
  mobileTextColor,
}) {
  const iconLookup = {
    facebook: faFacebookSquare,
    instagram: faInstagram,
    youtube: faYoutubeSquare,
  };

  return (
    <footer>
      <Nav aria-label="footer navigation">
        <div
          className="d-none d-xl-flex flex-column align-items-center w-100 px-10 py-6"
          style={generateCustomCss({
            backgroundColor: desktopBackgroundColor,
            textColor: desktopTextColor,
          })}
        >
          <div className="row justify-content-between align-items-center w-100 mw-1500">
            <div className="col-4 ">
              <ul
                className={classNames(
                  'list-unstyled mb-0 font-geo fs-10 fw-600',
                  styles.letter_spacing
                )}
              >
                {footerText.map((text) => {
                  return <li key={text}>{text}</li>;
                })}
              </ul>
            </div>
            <div className="col-4">
              <div className="d-flex flex-row justify-content-center align-items-center py-4 py-xl-6">
                <Image alt={logoTitle} height={82} src={logoUrl} width={82} />
              </div>
            </div>
            <div className="col-4">
              <div className="d-none d-xl-flex flex-row justify-content-end align-items-center">
                {socials.map(({ link: { href, title }, type }) => {
                  return renderSocialMediaCta({
                    color: desktopTextColor,
                    href,
                    iconLookup,
                    spacing: 'ms-24',
                    title,
                    type,
                  });
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex d-xl-none flex-column align-items-start w-100 px-14 py-10"
          style={generateCustomCss({
            backgroundColor: mobileBackgroundColor,
            textColor: mobileTextColor,
          })}
        >
          <div className="my-4">
            <Image alt={logoTitle} height={62} src={logoUrl} width={62} />
          </div>
          <div className="my-4">
            <ul
              className={classNames(
                'list-unstyled mb-0 font-geo fs-8 fw-600',
                styles.letter_spacing
              )}
            >
              {footerText.map((text) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center my-4">
            {socials.map(({ link: { href, title }, type }) => {
              return renderSocialMediaCta({
                color: mobileTextColor,
                href,
                iconLookup,
                spacing: 'me-16',
                title,
                type,
              });
            })}
          </div>
        </div>
      </Nav>
    </footer>
  );
}

export default DynamicDomainFooter;
