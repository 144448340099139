import { HttpsAgent } from 'agentkeepalive';
import ky from 'ky';
import logger from 'config/logger';
import {
  REQUEST_ERROR,
  REQUEST_RETRY,
  RESPONSE_RECEIVED,
  SENDING_REQUEST,
} from 'constants/log-messages';
import isBrowser from 'utils/is-browser';
import isEmpty from 'utils/is-empty';
import isObject from 'utils/is-object';
import sensitiveKeys from './sensitive-keys';

let keepAliveAgent;

if (!isBrowser) {
  keepAliveAgent = new HttpsAgent();
}

const noop = () => {};

const sensitiveHeaders = new Set(['authorization', 'access_token', 'api_key']);

function parseHeaders(headers) {
  try {
    const headerObj = {};

    /* eslint-disable unicorn/no-array-for-each */
    headers.forEach((value, key) => {
      headerObj[key] = sensitiveHeaders.has(key) ? '[REDACTED]' : value;
    });
    /* eslint-enable unicorn/no-array-for-each */

    return headerObj;
  } catch {
    return {};
  }
}

export class RequestClient {
  /**
   * @param {String} baseDomain base path for the services
   * @param {{beforeRequest: function, afterResponse: function, supressHooks: boolean, any}} options ky options
   */
  constructor(baseDomain = '/', options = {}) {
    this.baseDomain = baseDomain;
    this.options = options;
    this.requestInterceptor = this.requestInterceptor.bind(this);
    this.sanitizeError = this.sanitizeError.bind(this);
  }

  /**
   * This method will create the ky instance with default settings
   * @param {Object} options
   * @returns {any} instance of ky
   */
  instance = (options = {}) => {
    const {
      correlationId,
      hooks: { beforeRequest = noop } = {},
      log = logger,
      serviceName,
    } = options;

    const {
      supressHooks,
      beforeRequest: beforeRequestGlobal = noop,
      ...globalOptions
    } = this.options;

    const beforeRequestInterceptor = supressHooks
      ? []
      : [
          async (req) => {
            if (!isBrowser) {
              const { url, body, method, headers } = req;

              log.info(
                {
                  correlationId,
                  service: serviceName,
                  endpoint: url,
                  method,
                  headers: parseHeaders(headers),
                  data: body,
                },
                SENDING_REQUEST
              );
            }
          },
          this.requestInterceptor,
          beforeRequestGlobal,
          beforeRequest,
        ];

    return ky.create({
      agent: keepAliveAgent,
      prefixUrl: this.baseDomain,
      timeout: 25_000,
      headers: {
        'Content-Type': 'application/json',
      },
      retry: {
        limit: 3,
        methods: ['get', 'delete', 'put', 'post', 'patch'],
        statusCodes: [500, 408],
      },
      hooks: {
        beforeRequest: beforeRequestInterceptor,
        beforeError: [
          async (error) => {
            // set the error message from api
            const data = await error.response.json();
            if (typeof data?.message === 'string') {
              error.message = data.message;
            }

            if (!isBrowser) {
              const req = error?.request || {};
              const { url, method } = req;

              log.error(
                {
                  correlationId,
                  service: serviceName,
                  endpoint: url,
                  method,
                  error: data,
                },
                REQUEST_ERROR
              );
            }

            return error;
          },
          this.sanitizeError,
        ],
        beforeRetry: [
          async ({ request, error, retryCount }) => {
            if (!isBrowser) {
              const { url, method } = request;
              log.warn(
                {
                  correlationId,
                  service: serviceName,
                  endpoint: url,
                  method,
                  error,
                  retryCount,
                },
                REQUEST_RETRY
              );
            }
          },
        ],
      },
      ...globalOptions,
    });
  };

  /**
   * This method will make get request
   * @param {String} url context path
   * @param {Object} options options for the ky
   * @returns {Object}
   */
  get = async (url, options = {}) => {
    const {
      correlationId,
      hooks,
      log: passedLog,
      logger: passedLogger,
      serviceName,
      ...otherOptions
    } = options;

    const log = passedLog || passedLogger || logger;

    const res = await this.instance({
      correlationId,
      hooks,
      log,
      serviceName,
    }).get(url, {
      ...otherOptions,
    });

    if (!isBrowser) {
      log.info(
        {
          correlationId,
          service: serviceName,
          method: 'get',
          endpoint: url,
          response: {
            status: res.status,
            headers: parseHeaders(res.headers),
          },
        },
        RESPONSE_RECEIVED
      );
    }

    if (res.status === 204) return { status: 204 };

    return res.json();
  };

  /**
   * This method will make delete request
   * @param {String} url context path
   * @param {Object} options options for the ky
   * @returns {Object}
   */
  delete = async (url, options = {}) => {
    const {
      correlationId,
      hooks,
      log: passedLog,
      logger: passedLogger,
      serviceName,
      ...otherOptions
    } = options;

    const log = passedLog || passedLogger || logger;

    const res = await this.instance({
      correlationId,
      hooks,
      log,
      serviceName,
    }).delete(url, {
      ...otherOptions,
    });

    if (!isBrowser) {
      log.info(
        {
          correlationId,
          service: serviceName,
          method: 'delete',
          endpoint: url,
          response: {
            status: res.status,
            headers: parseHeaders(res.headers),
          },
        },
        RESPONSE_RECEIVED
      );
    }

    if (res.status === 204) return { status: 204 };
    return res.json();
  };

  /**
   * This method will make post request
   * @param {String} url context path
   * @param {Object} options options for the ky
   * @returns
   */
  post = async (url, options = {}) => {
    const {
      correlationId,
      hooks,
      log: passedLog,
      logger: passedLogger,
      serviceName,
      ...otherOptions
    } = options;

    const log = passedLog || passedLogger || logger;

    const res = await this.instance({
      correlationId,
      hooks,
      log,
      serviceName,
    }).post(url, {
      ...otherOptions,
    });

    if (!isBrowser) {
      log.info(
        {
          correlationId,
          service: serviceName,
          method: 'post',
          endpoint: url,
          response: {
            status: res.status,
            headers: parseHeaders(res.headers),
          },
        },
        RESPONSE_RECEIVED
      );
    }

    if (res.status === 204) return { status: 204 };

    return res.json().catch((err) => {
      if (res.status < 400) return { status: res.status };
      this.sanitizeError(err);
      throw err;
    });
  };

  /**
   * This method will make post request
   * @param {string} url context path
   * @param {object} options options for the ky
   * @returns
   */
  put = async (url, options = {}) => {
    const {
      correlationId,
      hooks,
      log: passedLog,
      logger: passedLogger,
      serviceName,
      ...otherOptions
    } = options;

    const log = passedLog || passedLogger || logger;

    const res = await this.instance({
      correlationId,
      hooks,
      log,
      serviceName,
    }).put(url, {
      ...otherOptions,
    });

    if (!isBrowser) {
      log.info(
        {
          correlationId,
          service: serviceName,
          method: 'put',
          endpoint: url,
          response: {
            status: res.status,
            headers: parseHeaders(res.headers),
          },
        },
        RESPONSE_RECEIVED
      );
    }

    if (res.status === 204) return { status: 204 };
    return res.json();
  };

  //   /**
  //  * This method will make patch request
  //  * @param {string} url context path
  //  * @param {object} options options for the ky
  //  * @returns
  //  */
  patch = async (url, options = {}) => {
    const {
      correlationId,
      hooks,
      log: passedLog,
      logger: passedLogger,
      serviceName,
      ...otherOptions
    } = options;

    const log = passedLog || passedLogger || logger;

    const res = await this.instance({
      correlationId,
      hooks,
      log,
      serviceName,
    }).patch(url, {
      ...otherOptions,
    });

    if (!isBrowser) {
      log.info(
        {
          correlationId,
          service: serviceName,
          method: 'patch',
          endpoint: url,
          response: {
            status: res.status,
            headers: parseHeaders(res.headers),
          },
        },
        RESPONSE_RECEIVED
      );
    }

    if (res.status === 204) return { status: 204 };
    return res.json();
  };

  /**
   * Ky hook to clean out sensitiveKeys data from error objects before throwing and logging them
   */
  sanitizeError(error) {
    if (error.options?.body) delete error.options.body;

    if (isObject(error?.options?.json) && !isEmpty(error?.options?.json)) {
      for (const key of Object.keys(error.options.json)) {
        if (sensitiveKeys.has(key)) {
          error.options.json[key] = '[REDACTED]';
        }
      }
    }

    if (
      isObject(error?.options?.searchParams) &&
      !isEmpty(error?.options?.searchParams)
    ) {
      for (const key of Object.keys(error.options.searchParams)) {
        if (sensitiveKeys.has(key)) {
          error.options.searchParams[key] = '[REDACTED]';
        }
      }
    }

    return error;
  }

  /**
   * This method will intercept the outgoing request and add headers
   * @param {Request} request
   */
  async requestInterceptor() {}

  /**
   * This method will be called everytime after response is recieved
   * @param {Request} request request object
   * @param {Object} options
   * @param {Response} response response object
   */
  async responseInterceptor(request, options, response) {
    return response;
  }
}

export const RequestInstance = new RequestClient('/');
