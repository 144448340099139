import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'components/Image';
import useContent from 'hooks/useContent';
import { getCart } from 'stores/actions/cart';
import { resetCart } from 'stores/reducers/cart';
import { hideCartWarningModal } from 'stores/reducers/cart-warning-modal';
import formatStoreName from 'utils/format-store-name';
import styles from './ModalClearCart.module.scss';

function ModalClearCart({ onMenuSelection, onStoreSelection, storeData }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const changeCartCardContent = useContent('change_cart_card');
  const {
    isShowing,
    pendingStoreName,
    pendingMenuSelection,
    pickupOrDelivery,
    navigate,
    navigateToOnCancel,
    type,
  } = useSelector((state) => state.cartWarningModal);
  const selectedStore = useSelector((state) => state.storeSelection);

  const pendingStoreData = storeData.find(
    (sd) => formatStoreName(sd.title) === formatStoreName(pendingStoreName)
  );

  if (!changeCartCardContent) return null;

  const {
    close_icon: closeIcon,
    change_cart_modal_header: changeCartStoreHeader,
    title: changeCartMenuHeader,
    change_cart_modal_body_text: changeCartStoreText,
    message: changeCartMenuText,
    modal_confirm: storeConfirm,
    cancel_button: cancelButton,
    confirm_button: menuConfirm,
    lume_icon: lumeIcon,
  } = changeCartCardContent;

  const closeModal = () => {
    if (navigateToOnCancel) {
      router.push(navigateToOnCancel);
    }
    dispatch(hideCartWarningModal());
  };

  return (
    <Modal show={isShowing} size="lg">
      <Modal.Body>
        <div className="d-flex flex-column align-items-center pb-32">
          <div className="d-flex justify-content-between w-100">
            {lumeIcon?.url ? (
              <Image
                src={lumeIcon.url}
                alt={lumeIcon.description}
                height={35}
                width={35}
              />
            ) : null}
            <Button variant="none" onClick={closeModal}>
              {closeIcon?.url ? (
                <Image
                  src={closeIcon.url}
                  alt={closeIcon.description}
                  height={35}
                  width={35}
                />
              ) : null}
            </Button>
          </div>

          <div className="text-center fs-16 font-freight text-primary pt-8">
            {type === 'store' ? changeCartStoreHeader : changeCartMenuHeader}
          </div>

          <div className="text-center font-acumin fs-8 text-primary my-16 pb-8">
            {type === 'store' ? changeCartStoreText : changeCartMenuText}
          </div>

          <div className="d-flex justify-content-center text-uppercase w-100">
            <Button
              className="fs-8 px-12 py-6 mb-8 w-100 w-md-75"
              data-value="update-cart"
              variant="secondary"
              onClick={async () => {
                const skipCartCheck = true;
                dispatch(hideCartWarningModal());
                if (type === 'store') {
                  await onStoreSelection(
                    pendingStoreName,
                    pendingStoreData,
                    pickupOrDelivery,
                    navigate,
                    skipCartCheck
                  );
                }
                if (type === 'menu') {
                  await onMenuSelection(pendingMenuSelection, skipCartCheck);
                  dispatch(resetCart());
                  dispatch(getCart(selectedStore));
                }

                // Steer users away from an empty bag page
                if (router?.pathname.includes('/bag')) {
                  await router.push('/');
                }
              }}
            >
              {type === 'store' ? storeConfirm : menuConfirm}
            </Button>
          </div>
          <div className="d-flex justify-content-center w-100">
            <Button
              data-value="cancel-updating-cart"
              variant="none"
              className={`text-primary border-2 border-primary font-geo fs-8 px-12 py-6 w-100 w-md-75 text-uppercase ${styles.cancel_button}`}
              onClick={closeModal}
            >
              {cancelButton}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(ModalClearCart);
