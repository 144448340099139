import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const favoriteProductsSlice = createSlice({
  name: 'favoriteProducts',
  initialState: [],
  reducers: {
    favoriteProduct(state, action) {
      return state && !state.includes(action.payload)
        ? [...state, action.payload]
        : [...state];
    },
    unfavoriteProduct(state, action) {
      return state.filter((s) => s !== action.payload);
    },
    setFavoriteProducts(state, action) {
      return [...action.payload];
    },
    clearFavoriteProducts() {
      return [];
    },
  },
  extraReducers,
});
export const { clearFavoriteProducts } = favoriteProductsSlice.actions;

export default favoriteProductsSlice;
