import { PROMO_KEY, clearPromo, setPromo } from 'stores/reducers/promo-code';
import isBrowser from 'utils/is-browser';

export const setPromoCode =
  ({ code, expirationDate }) =>
  (dispatch) => {
    dispatch(setPromo({ code, expirationDate }));
    if (isBrowser) {
      window.localStorage.setItem(
        PROMO_KEY,
        JSON.stringify({ code, expirationDate })
      );
    }
  };

// eslint-disable-next-line unicorn/consistent-function-scoping
export const clearPromoCode = () => (dispatch) => {
  dispatch(clearPromo());
  if (isBrowser) {
    window.localStorage.removeItem(PROMO_KEY);
  }
};
