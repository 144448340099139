import logger from 'config/logger';
import { RequestInstance } from 'request';
import favoriteProductsSlice from 'stores/reducers/favorite-products';
import { deleteUserInfo, getUserInfoFromLocalStorage } from './user';

const FAVORITE_PRODUCT_URI = 'api/favorites/skus';

export const addFavorite =
  ({ SKU }) =>
  async (dispatch) => {
    if (checkAuthClient(dispatch)) {
      dispatch(favoriteProductsSlice.actions.favoriteProduct(SKU));
      RequestInstance.post(FAVORITE_PRODUCT_URI, { json: { sku: SKU } }).catch(
        (err) => logger.error(err)
      );
    }
  };

export const removeFavorite =
  ({ SKU }) =>
  async (dispatch) => {
    if (checkAuthClient(dispatch)) {
      dispatch(favoriteProductsSlice.actions.unfavoriteProduct(SKU));
      RequestInstance.delete(FAVORITE_PRODUCT_URI, {
        json: { sku: SKU },
      }).catch((err) => logger.error(err));
    }
  };
// eslint-disable-next-line unicorn/consistent-function-scoping
export const initializeFavoriteProducts = () => async (dispatch) => {
  try {
    if (checkAuthClient(dispatch)) {
      const userInfo = getUserInfoFromLocalStorage();
      if (userInfo?.email) {
        const skus = await RequestInstance.get(FAVORITE_PRODUCT_URI);
        dispatch(favoriteProductsSlice.actions.setFavoriteProducts(skus ?? []));
      }
    }
  } catch (err) {
    logger.error(err, 'Error fetching favorite-skus');
  }
};

const checkAuthClient = (dispatch) => {
  const cookies = document.cookie
    .split(';')
    .map((v) => v.split('='))
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});
  if (!cookies.__refresh) {
    dispatch(favoriteProductsSlice.actions.clearFavoriteProducts());
    dispatch(deleteUserInfo);
    return false;
  }
  return true;
};
