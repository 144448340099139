/**
 * StoreAndMenu is responsible fopr maintaining store and menu state.
 * It dispatches redux actions when selections change.
 * It supplies store and menu details to child components that implement selection UI.
 */

import { useCallback, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { destroyCookie } from 'nookies';
import ModalClearCart from 'components/ModalClearCart';
import StoreSelection from 'components/StoreSelection';
import logger from 'config/logger';
import { GOOGLE_KEY } from 'hooks/constants';
import { useGoogleMapsApi } from 'hooks/useGoogleMapsApi';
import { RequestInstance } from 'request';
import { getCart } from 'stores/actions/cart';
import { setStoreMenu } from 'stores/actions/store-menu';
import { setStoreSelection } from 'stores/actions/store-selection';
import { resetCart } from 'stores/reducers/cart';
import { showCartWarningModal } from 'stores/reducers/cart-warning-modal';
import { getKeyByValue } from 'utils/key-by-value';

const storeSelector = (state) => state.storeSelection;
const storeMenuSelector = (state) => state.selectedMenu;
const showCartWarningSelector = (state) => state.cart?.lineItems.length > 0;
const isShowingCartWarningSelector = (state) =>
  state.cartWarningModal.isShowing;

function StoreAndMenu({
  closeButtonIconDescription,
  closeButtonIconUrl,
  hideTrigger = false,
  filterMenuOptions,
  storeSelectionContent,
}) {
  const google = useGoogleMapsApi(GOOGLE_KEY);
  const [storeData, setStoreData] = useState([]);
  const [storeMenuOptions, setStoreMenuOptions] = useState({});
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedMenu, setSelectedMenu] = useState('recreational');
  const selectedStoreFromState = useSelector(storeSelector);
  const selectedMenuFromState = useSelector(storeMenuSelector);
  const shouldShowCartWarning = useSelector(showCartWarningSelector);
  const isShowingCartWarning = useSelector(isShowingCartWarningSelector);
  const selectedStoreData = useSelector(
    (state) => state.selectedStoreInformation
  );
  const dispatch = useDispatch();
  const router = useRouter();

  const storeName = selectedStoreData?.title;

  useEffect(() => {
    if (storeName) {
      dispatch(getCart(storeName));
    }
  }, [dispatch, storeName]);

  /**
   * Account for server/client mismatch
   */
  useEffect(() => {
    setSelectedStore(selectedStoreFromState);
    setSelectedMenu(selectedMenuFromState);
  }, [selectedMenuFromState, selectedStoreFromState]);

  /**
   * @param {string} key key of menu from CMS data
   */
  const onMenuSelection = useCallback(
    async (key, skipCartCheck) => {
      if (shouldShowCartWarning && !skipCartCheck && !isShowingCartWarning) {
        return dispatch(
          showCartWarningModal({
            pendingMenuSelection: key,
            type: 'menu',
          })
        );
      }
      await dispatch(setStoreMenu(key));
      dispatch(resetCart());
      dispatch(getCart(selectedStore));
    },
    [dispatch, selectedStore, shouldShowCartWarning, isShowingCartWarning]
  );

  /**
   * This method will set menu based on the selected store
   * @param {String} storeName name of currently selected store
   * @returns
   */
  const setMenuByStore = useCallback(
    async (storeName) => {
      const currentMenu = storeMenuOptions[storeName] || [];
      if (currentMenu.length === 0) return;
      const currentSelectedValue = filterMenuOptions[selectedMenu];
      const isCurrentMenuAvailable = currentMenu.includes(currentSelectedValue);
      if (!isCurrentMenuAvailable) {
        const firstMenu = currentMenu[0];
        const firstMenuKey = getKeyByValue(filterMenuOptions, firstMenu);
        await onMenuSelection(firstMenuKey);
      }
    },
    [filterMenuOptions, selectedMenu, storeMenuOptions, onMenuSelection]
  );

  const onStoreSelection = useCallback(
    /**
     * @param {string} storeName e.g., 'Petersburg'
     * @param {object} storeData CMS data for the store
     * @param {string} pickupOrDelivery
     * @param {boolean} isNavigation if true navigate to the store
     * @param {boolean} skipCartCheck
     */
    async (
      storeName,
      selectedStoreData,
      pickupOrDelivery,
      isNavigation,
      skipCartCheck
      // eslint-disable-next-line max-params
    ) => {
      if (
        shouldShowCartWarning &&
        storeName !== selectedStoreFromState &&
        !skipCartCheck &&
        !isShowingCartWarning
      ) {
        return dispatch(
          showCartWarningModal({
            pendingStoreName: storeName,
            pickupOrDelivery,
            navigate: isNavigation,
            type: 'store',
          })
        );
      }

      const { pathname, query } = router;

      if (query.variant_sku && !pathname.includes('/shop')) {
        delete query.variant_sku;
        await router.replace({ pathname, query });
      }

      // when changing between shopping pages
      // most filters are incompatible between ct and dutchie,
      // so we just clear them and update
      if (pathname.includes('/shop')) {
        delete query.filters;
        if (query.sort === 'relevance') {
          query.sort = 'most_popular';
        }
        // direct the useEffect in hooks/useGetProducts to NOT refectch for this update
        // and mask the skip qs from the url
        query.skip = true;
        await router.replace(
          { pathname, query },
          query.sort ? `${pathname}?sort=${query.sort}` : pathname,
          { shallow: true }
        );
      }

      if (isNavigation && selectedStoreData?.url) {
        router.push(selectedStoreData?.url);
      }

      // eslint-disable-next-line unicorn/consistent-destructuring
      delete router.query.storeSelector;

      destroyCookie(null, '_dplus_abn_cid');

      await setMenuByStore(storeName);
      dispatch(setStoreSelection(selectedStoreData));
      if (pickupOrDelivery) {
        const pickUpOrDeliveryLowerCase = pickupOrDelivery.toLowerCase();
        window?.localStorage?.setItem(
          'pickupOrDelivery',
          pickUpOrDeliveryLowerCase
        );
        window?.localStorage?.setItem('CART_TYPE', pickUpOrDeliveryLowerCase);
      }
    },
    [
      dispatch,
      router,
      setMenuByStore,
      shouldShowCartWarning,
      selectedStoreFromState,
      isShowingCartWarning,
    ]
  );

  // fetch store data from contentstack when we mount
  useEffect(() => {
    const fetchStoreData = async () => {
      const response = await RequestInstance.get('api/store-information');
      const storeData = response || [];

      /* eslint-disable-next-line unicorn/no-array-reduce */
      const storeMenuOptions = storeData.reduce((acc, val) => {
        const { title, product_type: productType } = val;
        return {
          ...acc,
          [title]: productType,
        };
      }, {});

      setStoreData(storeData);
      setStoreMenuOptions(storeMenuOptions);
    };

    fetchStoreData().catch((err) => {
      logger.error(err, 'fetchStoreData');
    });
  }, []);

  return (
    <>
      <StoreSelection
        closeButtonIconDescription={closeButtonIconDescription}
        closeButtonIconUrl={closeButtonIconUrl}
        google={google}
        hideTrigger={hideTrigger}
        selectedStore={selectedStore}
        setStoreData={setStoreData}
        storeData={storeData}
        storeSelectionContent={storeSelectionContent}
        onStoreSelection={onStoreSelection}
      />
      <ModalClearCart
        storeData={storeData}
        onMenuSelection={onMenuSelection}
        onStoreSelection={onStoreSelection}
      />
    </>
  );
}

export default memo(StoreAndMenu);
