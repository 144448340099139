import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';
import isBrowser from 'utils/is-browser';

export const AGE_RESTRICTION_KEY = 'ageRestrictionAccepted';

const getAgeRestrictionAccepted = () => {
  try {
    if (isBrowser) {
      return JSON.parse(window.localStorage.getItem(AGE_RESTRICTION_KEY));
    }
    return false;
  } catch {
    return false;
  }
};

const ageGateSlice = createSlice({
  name: 'ageGate',
  initialState: getAgeRestrictionAccepted() || false,
  reducers: {
    setAgeRestriction(state, action) {
      return state || action.payload;
    },
  },
  extraReducers,
});

export default ageGateSlice;
