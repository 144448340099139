import { useState, useEffect, useRef } from 'react';
import logger from 'config/logger';
import { RequestInstance } from 'request';

/**
 * This hook returns content data from contentStack
 *
 * @param {string|[string]} contentType - the contentstack content type
 * @returns {[object]} - an array of corresponding content object or an empty object if an error is encountered with the fetch
 */
function useContent(types) {
  const ref = useRef([types].flat(1));

  const [contentTypes, setContentTypes] = useState(
    Array.isArray(types) ? types.map(() => null) : null
  );

  useEffect(() => {
    Promise.allSettled(
      ref.current.map((contentType) =>
        RequestInstance.get(`api/content/${contentType}`)
      )
    )
      .then((data) => {
        data = data.map((d) =>
          Array.isArray(d?.value)
            ? d?.value.length === 1
              ? d?.value[0]
              : d?.value
            : {}
        );
        setContentTypes(
          Array.isArray(data) && data.length === 1 ? data[0] : data
        );
      })
      .catch(logger.error);
  }, []);

  return contentTypes;
}

export default useContent;
