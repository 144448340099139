import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from './SearchIcon.module.scss';

const SearchIcon = ({ className, openSearchModal }) => {
  const classes = classNames(
    'd-flex align-items-center border-0 bg-transparent text-primary',
    className,
    styles.searchIconWrapper
  );

  return (
    <button
      aria-label="Search Products"
      className={classes}
      type="button"
      onClick={openSearchModal}
    >
      <div className={styles.searchIcon}>
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </button>
  );
};

export default SearchIcon;
