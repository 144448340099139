import { setCookie } from 'nookies';
import logger from 'config/logger';
import { RequestInstance } from 'request';
import { setStoreMenu } from 'stores/actions/store-menu';
import selectedStoreInformationSlice from 'stores/reducers/selected-store-information';
import storeSelectionSlice from 'stores/reducers/store-selection';
import formatStoreName from 'utils/format-store-name';
import isBrowser from 'utils/is-browser';
import { getCart } from './cart';

export const setStoreOnSSR = (storeName) => (dispatch) => {
  if (storeName) {
    dispatch(storeSelectionSlice.actions.setStoreSelection(storeName));
  }
};

export const setStoreSelection = (storeData) => (dispatch) => {
  try {
    if (storeData) {
      const storeName = storeData.title;
      dispatch(storeSelectionSlice.actions.setStoreSelection(storeName));
      setCookie(null, 'store', storeName, {
        maxAge: 365 * 24 * 60 * 60,
        path: '/',
      });
      dispatch(getCart(storeName));
      dispatch(
        selectedStoreInformationSlice.actions.setStoreInformation(storeData)
      );
      if (isBrowser) {
        window.localStorage.setItem('storeInfo', JSON.stringify(storeData));
      }
    }
  } catch (err) {
    logger.error(err);
  }
};

export const setStoreSelectionByName =
  (storeName, pickUpOrDelivery) => async (dispatch) => {
    const lumestores = await RequestInstance.get('api/store-information');
    const storeData = lumestores.find(
      (storeInfo) =>
        formatStoreName(storeInfo.title) === formatStoreName(storeName)
    );

    const { product_type: storeMenuOptions, delivery } = storeData;

    if (storeMenuOptions.length === 1) {
      dispatch(setStoreMenu(storeMenuOptions[0].toLowerCase()));
    }

    if (pickUpOrDelivery === 'delivery' && delivery?.zipcodes?.length > 0) {
      window?.localStorage?.setItem('pickupOrDelivery', pickUpOrDelivery);
      window?.localStorage?.setItem('CART_TYPE', pickUpOrDelivery);
    } else if (pickUpOrDelivery === 'pickup') {
      window?.localStorage?.setItem('pickupOrDelivery', pickUpOrDelivery);
      window?.localStorage?.setItem('CART_TYPE', pickUpOrDelivery);
    }

    return dispatch(setStoreSelection(storeData));
  };
