import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import styles from './HeaderMenuSection.module.scss';

export default function HeaderMenuSection({
  handleHeaderClick,
  handleMenuClick,
  menuSections,
}) {
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);

  function handleMenuOnClick(event, index, sections) {
    event.stopPropagation();
    event.preventDefault();
    setOpenMenuIndex(index);
    handleMenuClick(sections);
  }

  function handleHeaderOnClick() {
    setOpenMenuIndex(-1);
    handleHeaderClick();
  }

  useEffect(() => {
    document
      ?.querySelector('#header_container')
      ?.addEventListener('click', handleHeaderOnClick);

    return () => {
      if (document.querySelector('#header_container')) {
        document
          .querySelector('#header_container')
          .removeEventListener('click', handleHeaderOnClick);
      }
    };
  }, []);

  return (
    <div className="d-flex flex-row h-100 pt-6">
      {menuSections?.map((menuSection, idx) => {
        const { title = '', sections = [] } = menuSection ?? {};
        return (
          <Button
            key={title || idx}
            id="header_menu_button"
            className={classNames(
              'text-decoration-none',
              styles.header_menu_button,
              {
                [styles.activeMenuItem]: openMenuIndex === idx,
              }
            )}
            variant="none"
            onClick={(event) => {
              handleMenuOnClick(event, idx, sections);
            }}
          >
            <span className={styles.menu_text}>{title}</span>
          </Button>
        );
      })}
    </div>
  );
}
