import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

const initialState = { isShowing: false };

const upsellSlice = createSlice({
  name: 'upsellModal',
  initialState,
  reducers: {
    showUpsellModal(state, action) {
      return { ...state, isShowing: true, ...action.payload };
    },
    hideUpsellModal() {
      return initialState;
    },
  },
  extraReducers,
});

export const { showUpsellModal, hideUpsellModal } = upsellSlice.actions;
export default upsellSlice;
