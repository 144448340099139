/**
 * Since Ky adds all options to error, we
 * need to remove sensitive fields at some time before
 * logging the data
 */

const sensitiveKeys = new Set([
  'password',
  'confirm_password',
  'phone',
  'phone_number',
  'family_name',
  'last_name',
  'lastName',
  'birthday',
  'birthdate',
  'email',
]);

export default sensitiveKeys;
