import { setCookie } from 'nookies';
import menuSelectionSlice from 'stores/reducers/store-menu';

export const setStoreMenuSSR = (storeKey) => async (dispatch) => {
  if (storeKey) {
    dispatch(menuSelectionSlice.actions.selectMenu(storeKey));
  }
};

export const setStoreMenu = (storeKey) => async (dispatch) => {
  localStorage.setItem('medOrRec', storeKey);
  setCookie(null, 'menu', storeKey, {
    maxAge: 365 * 24 * 60 * 60,
    path: '/',
  });
  dispatch(menuSelectionSlice.actions.selectMenu(storeKey));
};
