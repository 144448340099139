import dayjs from 'dayjs';
import throttle from 'lodash.throttle';
import isBrowser from 'utils/is-browser';

const TIME_TILL_INACTIVE_MS = 300_000;

/**
 * setIdleInterval
 * runs a callback after an interval delaying if
 * the user is currently active (based on scroll, mousemove, touchstart)
 *
 * @param {()=> void} callback
 * @param {Number} ms interval bewteen callbacks
 * @returns {() => void} clean up function
 */
function setIdleInterval(callback, ms) {
  if (isBrowser && typeof callback === 'function' && ms) {
    let nextRefresh = dayjs().add(ms, 'milliseconds');
    let lastInteractive = dayjs();

    const handler = throttle(
      () => {
        lastInteractive = dayjs();
      },
      1000,
      { leading: true }
    );
    document.addEventListener('scroll', handler);
    document.addEventListener('mousemove', handler);
    document.addEventListener('touchstart', handler);

    const checkRefresh = (event) => {
      const now = dayjs();
      const sinceLastInteraction = now - lastInteractive;
      if (
        now > nextRefresh &&
        document.visibilityState === 'visible' &&
        (event?.type === 'visibilitychange' ||
          sinceLastInteraction > TIME_TILL_INACTIVE_MS)
      ) {
        callback();
        nextRefresh = now.add(ms, 'milliseconds');
      }
    };

    const id = setInterval(checkRefresh, 10_000);
    document.addEventListener('visibilitychange', checkRefresh);

    return () => {
      document.removeEventListener('visibilitychange', checkRefresh);
      document.removeEventListener('scroll', handler);
      document.removeEventListener('mousemove', handler);
      document.removeEventListener('touchstart', handler);
      clearInterval(id);
    };
  }
}

export default setIdleInterval;
