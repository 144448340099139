import { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import formatStoreName from 'utils/format-store-name';
import styles from './MessageBar.module.scss';

const NAVY = 'lume-primary-navy';
const GOLD = 'lume-secondary-gold';
const CREAM = 'lume-cream';
const DREAM = 'lume-dream-blue';
const GREY = 'lume-grey';
const MOVE = 'store-alert-move-red';
const ALL_STORES_TITLE = 'all stores';

const defaultMessage = {
  message: '',
  // eslint-disable-next-line camelcase
  color_scheme: GREY,
};

function MessageBar({ showOnlyAlertMessages = false, storeMessages = [] }) {
  const [selectedStore, setSelectedStore] = useState(ALL_STORES_TITLE);
  const selectedStoreInState = useSelector((state) => state.storeSelection);

  useEffect(() => {
    setSelectedStore(selectedStoreInState);
  }, [selectedStoreInState]);

  const storeSpecificMessages =
    storeMessages.find(
      (message) =>
        formatStoreName(message.title) === formatStoreName(selectedStore)
    )?.header_messages ?? [];

  const filteredStoreSpecificMessages = showOnlyAlertMessages
    ? storeSpecificMessages.filter((message) => message.color_scheme === MOVE)
    : storeSpecificMessages;

  const allStoresMessages =
    storeMessages.find(
      (message) =>
        formatStoreName(message.title) === formatStoreName(ALL_STORES_TITLE)
    )?.header_messages ?? [];

  const filteredAllStoresMessages = showOnlyAlertMessages
    ? allStoresMessages.filter((message) => message.color_scheme === MOVE)
    : allStoresMessages;

  const messages = [
    ...filteredStoreSpecificMessages,
    ...filteredAllStoresMessages,
  ];

  const isMultiMessage = messages.length > 1;
  const showMessageBar = messages.length > 0;

  const [currentIndex, setCurrentIndex] = useState(0);

  const message = messages[currentIndex] ?? messages[0] ?? defaultMessage;

  const decrementIndex = () =>
    setCurrentIndex(currentIndex <= 0 ? messages.length - 1 : currentIndex - 1);
  const incrementIndex = () =>
    setCurrentIndex(currentIndex >= messages.length - 1 ? 0 : currentIndex + 1);

  return (
    <div
      className={classNames(
        'd-flex',
        'align-items-center',
        'justify-content-center',
        styles.message_bar,
        {
          'bg-primary': showMessageBar && message.color_scheme === NAVY,
          'bg-secondary': showMessageBar && message.color_scheme === GOLD,
          'bg-light':
            showMessageBar && [DREAM, CREAM].includes(message.color_scheme),
          'bg-neutral-grey':
            showMessageBar && [GREY].includes(message.color_scheme),
          'bg-alert': showMessageBar && message.color_scheme === MOVE,
          'bg-white': !showMessageBar,
          'py-5 py-sm-6': showMessageBar && messages.length > 0,
          'text-primary': [DREAM, CREAM, GOLD, GREY].includes(
            message.color_scheme
          ),
          'text-white': [MOVE, NAVY].includes(message.color_scheme),
        }
      )}
    >
      {showMessageBar && (
        <>
          {isMultiMessage && (
            <button
              type="button"
              className={classNames(
                'btn btn-transparent position-absolute start-0 text-decoration-none ps-0 ps-md-4 pe-4',
                {
                  'text-primary': [DREAM, CREAM, GOLD].includes(
                    message.color_scheme
                  ),
                  'text-white': [MOVE, NAVY].includes(message.color_scheme),
                }
              )}
              onClick={decrementIndex}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          )}
          <div>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: message.message }}
              className={classNames(
                'font-acumin',
                'fs-6',
                'fs-xl-7',
                'fw-400',
                styles.message_bar
              )}
            />
          </div>
          {isMultiMessage && (
            <button
              type="button"
              className={classNames(
                'btn btn-transparent position-absolute end-0 text-decoration-none pe-0 pe-md-4 ps-4',
                {
                  'text-primary': [DREAM, CREAM, GOLD].includes(
                    message.color_scheme
                  ),
                  'text-white': [MOVE, NAVY].includes(message.color_scheme),
                }
              )}
              onClick={incrementIndex}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default memo(MessageBar);
